
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,Card,InputGroup,Collapse  } from 'react-bootstrap';
import "./cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";


class CardselectedType extends Component {
    constructor(props) {
        super(props)
        

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
        }
    }

    componentDidMount() {
        
       
    }
    render() {
        
       console.log("this.props.paymentPendingOption",this.props.paymentPendingOption)
        return (
           
            <fieldset class=""><legend>{this.props.stateData.englishSelected== true ? "Card Select Type" : "నమోదు సభ్యత్వం ఎంచుకోండి "}</legend> 
                {
                    this.props.paymentPendingOption == "paymentPending" ?
                    (
                        <Row>
                        <Col>
                            <div className="">
                                <Form.Check
                                        inline
                                        label={this.props.stateData.englishSelected== true ? `Life Time` : `జీవితకాల సభ్యత్వం `}
                                        name="cardSelectedTypeName"
                                        type="radio"
                                        id={`lifeTimeCardTypeId`}
                                        className="f-12 fw-bold color_black cursor_pointer"
                                        value="lifeCardSelected"
                                        checked={this.props.stateData.typeOfCardSelectedvalue == "lifeCardSelected" ? true : false}
                                        onChange={(e)=>this.props.cardSelectedType(e,"lifeCardSelected")}
                                        //disabled={this.props.stateData.newRegistrationInputObj.membershipType == "Permanent" ? false : true}
                                ></Form.Check>
                                <Form.Check
                                        inline
                                        label={this.props.stateData.englishSelected== true ? `General` : `సాధారణ సభ్యత్వం `}
                                        name="cardSelectedTypeName"
                                        type="radio"
                                        id={`generalCardTypeId`}
                                        className="f-12 fw-bold color_black cursor_pointer"
                                        value="generalCardSelected"
                                        checked={this.props.stateData.typeOfCardSelectedvalue == "generalCardSelected" ? true : false}
                                        onChange={(e)=>this.props.cardSelectedType(e,"generalCardSelected")}
                                        disabled={this.props.stateData.newRegistrationInputObj.membershipType == "Permanent" ? true : false}
                                ></Form.Check>
                                
                            </div>
                        </Col>
                    </Row>
                    )
                    :
                    (
                        <Row>
                        <Col>
                            <div className="">
                                <Form.Check
                                        inline
                                        label={this.props.stateData.englishSelected== true ? `Life Time` : `జీవితకాల సభ్యత్వం `}
                                        name="cardSelectedTypeName"
                                        type="radio"
                                        id={`lifeTimeCardTypeId`}
                                        className="f-12 fw-bold color_black cursor_pointer"
                                        value="lifeCardSelected"
                                        checked={this.props.stateData.typeOfCardSelectedvalue == "lifeCardSelected" ? true : false}
                                        onChange={(e)=>this.props.cardSelectedType(e,"lifeCardSelected")}
                                ></Form.Check>
                                <Form.Check
                                        inline
                                        label={this.props.stateData.englishSelected== true ? `General` : `సాధారణ సభ్యత్వం `}
                                        name="cardSelectedTypeName"
                                        type="radio"
                                        id={`generalCardTypeId`}
                                        className="f-12 fw-bold color_black cursor_pointer"
                                        value="generalCardSelected"
                                        checked={this.props.stateData.typeOfCardSelectedvalue == "generalCardSelected" ? true : false}
                                        onChange={(e)=>this.props.cardSelectedType(e,"generalCardSelected")}
                                ></Form.Check>
                                
                            </div>
                        </Col>
                    </Row>
                    )
                }
                
                

                    {
                        this.props.stateData.typeOfCardSelectedvalue == "lifeCardSelected" ?
                        (
                            <Row>
                                <Col>
                                    <h6 className='color_red mt-2 f-12'>{this.props.stateData.englishSelected== true ? "Your Selected Life Time Membership Card" : "మీరు జీవితకాల సభ్యత్వం  ఎంచుకున్నారు"} </h6>
                                    <h6 className='color_red mt-2 f-12'>{this.props.stateData.englishSelected== true ? "Amount Rs 100000/-" : " Amount Rs 100000/-"} </h6>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            this.props.stateData.typeOfCardSelectedvalue == "generalCardSelected" ?
                            (
                                <Row>
                                    <Col>
                                        <h6 className='color_red mt-2 f-12'>{this.props.stateData.englishSelected== true ? "Your Selected General Membership Card" : "మీరు సాధారణ సభ్యత్వం  ఎంచుకున్నారు"} </h6>
                                        <h6 className='color_red mt-2 f-12'>{this.props.stateData.englishSelected== true ? "Amount Rs 100/- 2 Years" : " Amount Rs 100/- 2Years"} </h6>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        )
                    }
            </fieldset>
        )
    }
  }
  
  export default withRouter(CardselectedType);
  