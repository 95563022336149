
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,OverlayTrigger,Tooltip,Button,Form,Card,InputGroup } from 'react-bootstrap';
import "./Dashboard.css";
import { withRouter,Link } from "react-router-dom";
import { getAPICallData } from '../../services/MemberService';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import { getCampaignAPICallData } from '../../services/MemberService';
import moment from 'moment';
import NTRTRUSTLOGO from '../../images/ntr_trust_logo.png';
import LOGORED from '../../images/logo_red.png';
import LOGOREDTEXT from '../../images/logo_red_text.png';
import PLAYSTORE from '../../images/play_store.png';
import IPHONE from '../../images/iphone.png';
import MANATDP from '../../images/mana_tdp.jpeg';
import mahanadu_bg from '../../images/mahanadu_bg.jpeg';
import mahanadu_logo from '../../images/mahanadu_logo.png';
import manifest_logo from '../../images/manifest_logo.jpeg';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import voter_survey_image from '../../images/voter_survey_image.png'
import "react-modal-video/css/modal-video.css";
import ModalVideo from 'react-modal-video';
import BGLOGO from '../../images/BG_logo.png';
import CBNSUPPORT from '../../images/cbn_support.png'
import {
    FacebookShareButton,
    InstapaperShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import * as Cookies from "es-cookie";
import EventRestrictModal from './EventRestrictModal';
import EnabledLocationPopup from '../Volunteer/EnabledLocationPopup'; 
import loader from '../../images/loader.gif';
import EventAttendamceModal from './EventAttendamceModal';
import { getAPICallDataForCadreSaving } from '../../services/MemberService';
import QRCode from "react-qr-code";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            bot_name: window.User !=undefined ? window.User.bot_name : "",
            userAccessList:JSON.parse(localStorage.getItem('userAccessList')),
            accessName:localStorage.getItem('accessName'),
            roleName:localStorage.getItem('roleName'),
            votersSurveyInfoBlock:false,
            isOpen: false,
            viewDescriptionModal:false,
            selectedCampaignDataArr:[],
            targetBGModalOpen:false,
            totalHouses: 0,
            visitedHouses: 0,
            todaycnt: 0,
            pendingTodayCount:0,
            usersCnt:0,
            eventRestrictShowModal:false,
            useCurrentLocationSymbolD:"hide",
            checkingEnabledLocationModal:false,
            useCurrentLocationSymbolV:"hide",
            userLatitude:"",
            userLongitude:"",
            userLocationMsg:"",
            eventClickType:"",
            checkingLocationServiceEnabledObj:{},
            delaylocationModal:false,
            eventAttendanceModalShow:true,
            roleAccessId:"",
            constituencyValue:"",
            referalMembershipId:""
        }
        this.openModal = this.openModal.bind(this)
    }
  
    componentDidMount() {
        this.setState({targetBGModalOpen: true});
        this.getUserRoleDetailsByUserIdDash();
        this.getMembershipIdByChatId();
       // this.getAllProgramsForDsh();
       /* const userAccessObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID
        }
      ); 

      getAPICallData(userAccessObj,"getUserAccessLocationDetails")
          .then((res) => res.json())
          .then((userAccessList) => {
              if(userAccessList != null && userAccessList.status === "Success"){
                this.setState({ userAccessList: userAccessList});
              }else{
                this.props.history.push({pathname:"/dashboard"});
              }
      });  */

      if(this.state.userAccessList != null && this.state.userAccessList.status === "Success"){
        this.setState({
                constituencyValue: {
                value: this.state.userAccessList.constituencyList[0].id,
                label: this.state.userAccessList.constituencyList[0].name
            }
        });
      }else{
        this.setState({
            constituencyValue: {
            value: 0,
            label: ""
        }
    });
        this.props.history.push("/dashboard");
      }
      

      //this.getUserFGEnrollmentStatus();

    }

    
    getUserFGEnrollmentStatus = () => {
        const userAccessObj =  
        JSON.stringify({ 
          "chatId" :this.state.chatID
        }
      ); 

      getAPICallData(userAccessObj,"FTRGRT/getUserFGEnrollmentStatus")
          .then((res) => res.json())
          .then((data) => {
              if(data != null){
                var pendingToday = 0;
                if(data.todaycnt > 0){
                    pendingToday = 40 - data.todaycnt;
                }else if(data.todaycnt == 40){
                    pendingToday = 0;
                }else if(data.todaycnt == 0){
                    pendingToday = 40;
                }
                
                this.setState({ totalHouses: data.totalHouses,
                    visitedHouses: data.visitedHouses,
                    todaycnt: data.todaycnt,
                    pendingTodayCount:pendingToday,usersCnt:data.usersCnt});
              }else{
                this.setState({ totalHouses: 0,
                    visitedHouses: 0,
                    todaycnt: 0,
                    pendingTodayCount:0,usersCnt:0});
              }
      }); 
    }

votersSurveyBlock = () => {
    this.setState({ votersSurveyInfoBlock: !this.state.votersSurveyInfoBlock});
    
}

openModal () {
    this.setState({isOpen: true})
  }

  viewDescriptionClick = () => {
    this.setState({viewDescriptionModal: true})
  }
    
  getAllProgramsForDsh = () => {
    this.setState({ selectedCampaignDataArr:[]});
    
    const userAccessObj =  
    JSON.stringify({ 
      "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
    }
  ); 

  getCampaignAPICallData(userAccessObj,"getAllCampaignsList")
      .then((res) => res.json())
      .then((data) => {
         if(data.otherCampaigns !=null && data.otherCampaigns.length>0){
          this.setState({ selectedCampaignDataArr: data.otherCampaigns});
         }else{
          this.setState({ selectedCampaignDataArr: []});
         }
      });
  }

  

  getUserRoleDetailsByUserIdDash = () => {
    const BGObj =  
          JSON.stringify({ 
            "username" : "bsa_api",
            "password" : "9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :this.state.chatID
          }); 
          getAPICallData(BGObj,"getUserRoleDetailsByUserId")
          .then((res) => res.json())
          .then((data) => {
            if(data !=null){
              this.setState({ roleAccessId:data }); 
            }else{
              this.setState({ roleAccessId:""}); 
            }
            
          }); 
  }

  getMembershipIdByChatId = () => {
    const BGObj =  
          JSON.stringify({ 
            "chatId" :this.state.chatID
          }); 
          getAPICallDataForOnlyBSA(BGObj,"getMembershipIdByChatId")
          .then((res) => res.json())
          .then((data) => {
            if(data !=null){
              this.setState({ referalMembershipId:data.membershipId}); 
            }else{
              this.setState({ referalMembershipId:""}); 
            }
            
          }); 
  }

  navigateToWhatsApp = (membershipId) => {
    // Construct the WhatsApp sharing URL
    const whatsappURL = `https://wa.me/919053419999?text=TDP_RI_${membershipId}`;

    // Open the WhatsApp sharing link in a new tab
    window.open(whatsappURL, '_blank');
    
  }

  navigateToWhatsApp2 = (membershipId) => {
    // Construct the WhatsApp sharing URL
    const whatsappURL = `https://wa.me/919134100009?text=TDP_RI_${membershipId}`;

    // Open the WhatsApp sharing link in a new tab
    window.open(whatsappURL, '_blank');
    
  }

  navigateToWhatsApp3 = (membershipId) => {
    // Construct the WhatsApp sharing URL
    const whatsappURL = `https://wa.me/?text=https://tdp1.in/membership2024.action?RI=${membershipId}`;

    // Open the WhatsApp sharing link in a new tab
    window.open(whatsappURL, '_blank');
    
  }
 

    render() {
        const queryParams = new URLSearchParams(window.location.search);
        let bot_name = queryParams.get('bot_name');
        
        
        console.log("dddddd",Cookies.get("role"))
        console.log("dddddd",Cookies.get("BGAccess"));


        var socialMediaArr=[
            {'title':"TELUGUDESAM PARTY MEMBERSHIP 2022-24 REGISTRATION & RENEWAL - WhatsApp URL-1",'url':`https://wa.me/919053419999?text=TDP_RI_${this.state.membershipId}`},
            {'title':"TELUGUDESAM PARTY MEMBERSHIP 2022-24 REGISTRATION & RENEWAL - WhatsApp URL-2",'url':`https://wa.me/919134100009?text=TDP_RI_${this.state.membershipId}`},
            {'title':"TELUGUDESAM PARTY MEMBERSHIP 2022-24 REGISTRATION & RENEWAL - Web URL",'url':`https://wa.me/?text=https://tdp1.in/membership2024.action?RI=${this.state.membershipId}`}

        ];

        
        
        return (
           
            <section>
                <Container fluid className=''>
                <Row>
                            <Col>
                                <Card className='mt-2'>
                                    <Card.Body className='p-2'>
                                    <Row>
                                            <Col>
                                                <div className='flex-title border-bottom-double'>
                                                    <h6 className='fw-bold text-center ln-28 f-22'>సభ్యత్వ నమోదు కార్యక్రమం <br/> 2024</h6>
                                                    <Image variant="top" src={require('../../images/membership.jpg')} className='mm_cls' />
                                                </div>
                                            </Col>
                                        </Row>
                                    
                                        <Card.Text>
                                            <Row>
                                                <Col className='fw-bold text_css mt-3'>
                                                తెలుగుదేశం పార్టీ సభ్యత్వ నమోదు కార్యక్రమం
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col className='text_decoration_none_a'>
                                                <Link to={{
                                                                pathname: "/cadre_registration",
                                                                params: {
                                                                    navbarDisplay:"hide",
                                                                    //userAccessList:this.state.userAccessList,
                                                                    
                                                                }
                                                                }} >
                                                                <Button variant="outline-danger" className='f-12 mt-3 fw-bold float-end' >నమోదు కోసం <i class="fa-solid fa-arrow-right ms-1"></i></Button>
                                                        </Link>
                                                </Col>
                                            
                                            </Row>
                                            
                                        </Card.Text>
                                        
                                        
                                    </Card.Body>
                                </Card>
                                
                            </Col>
                        </Row>
                

                    {
                        this.state.roleAccessId == "" ?
                        (
                            ""
                        )
                        :
                        (
                            
                            <>

                             
                            {
                                       ((this.state.roleAccessId.id == "1" || this.state.roleAccessId.id == "2" || this.state.roleAccessId.id == "3" || this.state.roleAccessId.id == "4" ||  this.state.roleAccessId.id == "15" || this.state.roleAccessId.id == "18") && (this.state.userAccessList != null && this.state.userAccessList.status === "Success")) ? 
                                        (
                                            <>
                                            {
                                                this.state.referalMembershipId != undefined ?
                                                (
                                                    <Row>
                                                    <Col>
                                                        <Card className='mt-2'>
                                                            <Card.Body className='p-2'>
                                                            <Row>
                                                                    <Col>
                                                                        <div className='flex-title border-bottom-double'>
                                                                            <h6 className='fw-bold text-center ln-28 f-22'>రిఫరల్ లింక్‌</h6>
                                                                            <Image variant="top" src={require('../../images/referAFriend.png')} className='mm_cls' />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            
                                                                <Card.Text>
                                                                    <Row>
                                                                        <Col className='fw-bold text_css mt-3'>
                                                                        మీ  రిఫరల్ లింక్‌ను స్నేహితులు మరియు కుటుంబ సభ్యులతో పంచుకోండి. మీరు తీసుకొచ్చిన ప్రతి కొత్త సభ్యుడు మన తెలుగుదేశం పార్టీని  మరింత బలంగా ఎదగడానికి సహకరిస్తారు. మీ  రిఫరల్ లింక్‌తో మరింత మంది సభ్యులను జోడించడం ద్వారా మీ నెట్‌వర్క్ విస్తరిస్తున్న కొద్దీ, మన  తెలుగుదేశం పార్టీలో మీ ప్రభావం కూడా పెరుగుతుంది.
                                                                        </Col>
                                                                    </Row>
                                                                    
                                                                    <div class="row">
                                                                        <div class="col-sm-12">
                                                                            <h6 class="f-14 text-muted mt-4">share on </h6>
                                                                            <div class="flex-align-share mt-2">
                                                                                <div >
                                                                                    <a className="wt-color"> <WhatsappShareButton url={`https://wa.me/919053419999?text=TDP_RI_${this.state.referalMembershipId}`} title="TELUGUDESAM PARTY MEMBERSHIP 2022-24 REGISTRATION & RENEWAL - WhatsApp URL 1 : ">
                                                                                        <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" ><i class="fa-brands fa-whatsapp color_red"></i> WhatsApp URL 1 </button>
                                                                                        </WhatsappShareButton>
                                                                                    </a>
                                                                                   
                                                                                </div>
                                                                                <div >
                                                                                    <a className="wt-color"> <WhatsappShareButton url={`https://wa.me/919134100009?text=TDP_RI_${this.state.referalMembershipId}`} title="TELUGUDESAM PARTY MEMBERSHIP 2022-24 REGISTRATION & RENEWAL - WhatsApp URL 2 : ">
                                                                                        <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" ><i class="fa-brands fa-whatsapp color_red"></i> WhatsApp URL 2 </button>
                                                                                        </WhatsappShareButton>
                                                                                    </a>
                                                                                    
                                                                                </div>
                                                                                <div >
                                                                                    <a className="wt-color"> <WhatsappShareButton url={`https://wa.me/?text=https://tdp1.in/membership2024.action?RI=${this.state.referalMembershipId}`} title="TELUGUDESAM PARTY MEMBERSHIP 2022-24 REGISTRATION & RENEWAL - Web URL : ">
                                                                                    <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" ><i class="fa-solid fa-globe color_red"></i> Web URL </button>
                                                                                        </WhatsappShareButton>
                                                                                    </a>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
    
                                                                    <div class="row">
                                                                        <div class="col-sm-12 mt-3">
                                                                        <h6 class="f-14 text-muted">Click To Below URLs To Copy</h6>
                                                                            <div class="flex-align-share mt-2">
                                                                                <div >
                                                                                    <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" onClick={() => {navigator.clipboard.writeText(`https://wa.me/919053419999?text=TDP_RI_${this.state.referalMembershipId}`)}}><i class="fa-solid fa-copy color_red"></i> WhatsApp URL 1 </button>
                                                                                </div>
                                                                                <div >
                                                                                    <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12" onClick={() => {navigator.clipboard.writeText(`https://wa.me/919134100009?text=TDP_RI_${this.state.referalMembershipId}`)}}><i class="fa-solid fa-copy color_red"></i> WhatsApp URL 2 </button>
                                                                                </div>
                                                                                <div >
                                                                                    <button type="button" class="fw-bold btn btn-sm  btn-outline-danger f-12"  onClick={() => {navigator.clipboard.writeText(`https://wa.me/?text=https://tdp1.in/membership2024.action?RI=${this.state.referalMembershipId}`)}}><i class="fa-solid fa-copy color_red"></i> Web URL </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    
                                                                    
                                                                </Card.Text>
                                                                
                                                                
                                                            </Card.Body>
                                                        </Card>
                                                        
                                                    </Col>
                                                </Row>
                                                )
                                                :
                                                ("")
                                            }
                                            
                                           

                                            <Row>
                                                    <Col>
                                                        <Card className='mt-3'>
                                                            <Card.Header className='text-center fw-bold bg_color_yellow'>
                                                                <Row>
                                                                    <Col>
                                                                        <div className='flex-title'>
                                                                            <h6 className='fw-bold text-center ln-28'>100 డేస్ డోర్ టూ డోర్ రిజిస్ట్రేషన్</h6>
                                                                        </div>
                                                                        
                                                                    </Col>
                                                                    
                                                                </Row>
                                                                
                                                            </Card.Header>
                                                            
                                                            <Card.Body className='p-2'>
                                                                <Card.Text className='fw-bold text_css'>
                                                                100 డేస్ డోర్ టూ డోర్   కార్యక్రమంలో భాగంగా మీరు మీ పరిథిలోని పోలింగ్ బూత్ నందు ఓటర్లని ఈ కార్యక్రమంలో రిజిస్టర్ చేయుటకు ఈ క్రింది లింక్ ని క్లిక్ చేసి నమోదు చేయగలరు
                                                                </Card.Text>
                                                                {
                                                                    this.state.userAccessList != null && this.state.userAccessList.status === "Success" ?
                                                                    (
                                                                        <Row>
                                                                            <Col>
                                                                                    <Link to={{
                                                                                            pathname: "/bsbg_dashboard_new",
                                                                                            params: {
                                                                                                navbarDisplay:"hide",
                                                                                                userAccessList:this.state.userAccessList,
                                                                                                roleName:this.state.roleName,
                                                                                                accessName:this.state.accessName,
                                                                                                campaignId:7
                                                                                            }
                                                                                            
                                                                                        }} >
                                                                                        <Button variant="outline-danger" className='float-end f-12  fw-bold'>VIEW DASHBOARD  <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                                    </Link>
                                                                                </Col>
                                                                            <Col>
                                                                                    <Link to={{
                                                                                            pathname: "/bsbg_shankharavam_new",
                                                                                            params: {
                                                                                                navbarDisplay:"hide",
                                                                                                userAccessList:this.state.userAccessList,
                                                                                                campaignId:7
                                                                                            }
                                                                                            
                                                                                        }} >
                                                                                        <Button variant="outline-danger" className='float-end f-12  fw-bold'>START WITH REGISTER  <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                                    </Link>
                                                                                </Col>
                                                                            </Row>
                                                                    )
                                                                    :
                                                                    (
                                                                        ""
                                                                    )
                                                                }
                                                                
                                                            </Card.Body>
                                                        </Card>
                                                    
                                                    </Col>
                                                </Row>
                                            </>
                                           
                                        )
                                        :
                                        ("")
                                    }

                            {/*
                                 this.state.chatID == "5129193582" || this.state.chatID == "943827154" || this.state.chatID == "6899079589" || this.state.chatID == "1048868711" ||
                                 this.state.chatID == "1032335009" || this.state.chatID == "1343549617" || this.state.chatID == "2145683358"  || this.state.chatID == "339366859" ?

                                 (
                                    <>
                                    
                                    <Row>
                                    <Col>
                                        <Card className='mt-2'>
                                            <Card.Body className='p-2'>
                                            <Row>
                                                    <Col>
                                                        <div className='flex-title border-bottom-double'>
                                                            <h6 className='fw-bold text-center ln-28 f-22'>MY CUBS</h6>
                                                            <Image variant="top" src={require('../../images/team.png')} className='w-30' />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            
                                                <Card.Text>
                                                    <Row>
                                                        <Col className='fw-bold text_css mt-3'>
                                                        మీ CUBS వివరాలు - రాబోయే ఎలెక్షన్స్ అందరూ అనుసంధానం అవుటకు - తెలుగుదేశం మరియు కూటమి అభ్యర్థులను గెలిపించుటకు
                                                        </Col>
                                                    </Row>
                                                    
                                                    <Row>
                                                        <Col className='text_decoration_none_a'>
                                                        <Link to={{
                                                                        pathname: "/my_team",
                                                                        params: {
                                                                          navbarDisplay:"show",
                                                                          userAccessList:this.state.userAccessList,
                                                                          accessName:this.state.accessName,
                                                                          roleName:this.state.roleName,
                                                                          roleId:this.state.roleAccessId.id
                                                                        }
                                                                      }} >
                                                                        <Button variant="outline-danger" className='f-12 mt-3 fw-bold float-end' >MY CUBS <i class="fa-solid fa-arrow-right ms-1"></i></Button>
                                                              </Link>
                                                        </Col>
                                                    
                                                    </Row>
                                                    
                                                </Card.Text>
                                               
                                                
                                            </Card.Body>
                                        </Card>
                                        
                                    </Col>
                                </Row>
                                </>
                                 )
                                 :
                                 ("")
                            */}
                           
                                    
                                       {/* <Row>
                                        <Col>
                                            <Card className='mt-2'>
                                                <Card.Img variant="top" src={require('../../images/shankaravam.jpeg')} className='img_border_bottom_raduis height_201' />
                                                <Card.Body className='p-2'>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className='fw-bold text-center f-18'>
                                                                <span className='color_green'>My QRCODE</span> For Attendence
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Card className="text-white border-none mt-3 mb-3">
                                                                        <div className='center-auto'>
                                                                            <QRCode
                                                                                size={200}
                                                                                viewBox={`0 0 256 256`}
                                                                                value={`${this.state.constituencyValue.value == undefined  ? "" : this.state.constituencyValue.value} - ${this.state.chatID}`}
                                                                            />
                                                                        </div> 
                                                                
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <h5 className='color_red fw-bold mt-2 f-14 ln-28 text-center'>మీరు శంఖారావం కార్యక్రమానికి హాజరైనట్లు గుర్తించడానికి ఈ  QR కోడ్ చూపించి - వాలంటీర్లు వద్ద స్కాన్ చేయంచుకోండి</h5>
                                                            </Col>
                                                        </Row>
                                                       
                                                    
                                                
                                                    
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            <Card className='mt-2'>
                                                <iframe id="ytplayerId" type="text/html" width="100%" height="200"
                                                src="https://www.youtube.com/embed/Wc2aZSmjXpA?autoplay=0&origin=https://ui.projectkv.com"
                                                frameborder="0" className='border-left-right-radius'/>
                                                    <Card.Body className='p-2'>
                                                        <Card.Text>
                                                            <Row>
                                                                <Col>
                                                                    <h6 className='f-14'>Share On : </h6>
                                                                    <ul class="social-media-icons mt-3 pad_left_0">
                                                                        <li>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>share on Facebook</Tooltip>}>
                                                                                <a className="fb-color" > <FacebookShareButton url={"https://www.youtube.com/watch?v=Wc2aZSmjXpA"} title="Nara Lokesh Shankaravam : ">
                                                                                    <i class="fab fa-facebook-f icon"></i>
                                                                                </FacebookShareButton></a>
                                                                            </OverlayTrigger>

                                                                        </li>
                                                                        <li>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>share on Whatsapp</Tooltip>}>
                                                                                <a className="wt-color"> <WhatsappShareButton url={"https://www.youtube.com/watch?v=Wc2aZSmjXpA" } title="Nara Lokesh Shankaravam : ">
                                                                                    <i class="fab fa-whatsapp icon"></i>
                                                                                </WhatsappShareButton>
                                                                                </a>
                                                                            </OverlayTrigger>

                                                                        </li>
                                                                        <li>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>share on Telegram</Tooltip>}>
                                                                                <a className="te-color"> <TelegramShareButton url={"https://www.youtube.com/watch?v=Wc2aZSmjXpA"} title="Nara Lokesh Shankaravam : ">
                                                                                    <i class="fab fa-telegram icon"></i>
                                                                                </TelegramShareButton>
                                                                                </a>
                                                                            </OverlayTrigger>

                                                                        </li>
                                                                        
                                                                        <li>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>share on Twitter</Tooltip>}>
                                                                                <a className="tw-color"><TwitterShareButton url={"https://www.youtube.com/watch?v=Wc2aZSmjXpA" } title="Nara Lokesh Shankaravam : ">
                                                                                    <i class="fab fa-twitter icon"></i>
                                                                                </TwitterShareButton>
                                                                                </a>
                                                                            </OverlayTrigger>

                                                                        </li>

                                                                    </ul>
                                                                </Col>
                                                                
                                                            </Row>
                                                        
                                                    
                                                        
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            
                                        </Col>
                                </Row>*/}
                           
                            
                            
                            {/*
                                this.state.roleAccessId.id != "17" && this.state.roleAccessId.id != "16" && this.state.roleAccessId.id != "11" && this.state.roleAccessId.id != "15" && Cookies.get("role") != ""  ?
                                    (
                                        <Row>
                                        <Col>
                                            <Card className='mt-2'>
                                                <Card.Body className='p-2'>
                                                <Row>
                                                        <Col>
                                                            <div className='flex-title border-bottom-double'>
                                                                <h6 className='fw-bold text-center ln-28 f-18'>భవిష్యత్తుకు&nbsp;గ్యారెంటీ <br/><span className='color_red ml-0'>లక్ష్యం</span></h6>
                                                                <Image variant="top" src={require('../../images/target_enroll.png')} className='w-30' />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className='fw-bold text_css mt-2 text_left'>
                                                                {
                                                                    Cookies.get("role") == 'booth' ?
                                                                    (<span style={{textAlign:"left",marginLeft:"0px"}}> మీరు మీ బూత్ నందు అన్ని కుటంబాలను త్వరగా కలిసి  
                                                                        బాబు ష్యూరిటీ - భవిష్యత్తుకు గ్యారెంటీ పథకాల గురించి తెలియజేసి ఈ కార్యక్రమాన్ని విజయవంతం చేయగలరు.</span>)
                                                                    :
                                                                    (<span style={{textAlign:"left",marginLeft:"0px"}}> మీ <b>{Cookies.get("role") == "constituency" ? 'నియోజకవర్గంలో' : Cookies.get("role") == "unit" ? 'యూనిట్' : Cookies.get("role") == "cluster" ? 'క్లస్టర్' : Cookies.get("role") == "Mandal" ? 'మండల్' : ''  }</b> క్రిందకు వచ్చే బూత్ ఇంచార్జిల తో కలిసి, అన్ని బూత్ లలో ఈ కార్యక్రమం జరిగే విధంగా చేయగలరు.</span>)
                                                                }
                                                                
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className=''>
                                                                <p className='text_css mt-3 text_left'>ప్రతి {Cookies.get("role") == 'booth' ? 'రోజు' : 'బూత్ వారీగా'} ఈ కార్యక్రమం జరిగిన వివరాలు తెలుసుకొనుటకు  
                                                                {
                                                                    this.state.userAccessList != null && this.state.userAccessList.status === "Success" ?
                                                                    (
                                                                            <Link to={{
                                                                                    pathname: "/enrollment_dashboard",
                                                                                    params: {
                                                                                        navbarDisplay:"hide",
                                                                                        volunteerDts:this.state.volunteerDts,
                                                                                        userAccessList:this.state.userAccessList,
                                                                                        accessName:this.state.accessName,
                                                                                        roleName:Cookies.get("role")
                                                                                    }
                                                                                    
                                                                                }} >
                                                                                <span className='fw-bold color_red text-decoration-underline' style={{textAlign:"left",marginLeft:"0px"}}> ఇక్కడ క్లిక్ చేయండి <i class="fa-solid fa-arrow-right ms-1"></i></span>
                                                                            </Link>
                                                                                
                                                                    )
                                                                    :
                                                                    (
                                                                        ""
                                                                    )
                                                                }
                                                                </p>
                                                                
                                                            </Col>
                                                        </Row>
                                                        <hr className='mb-2 mt-0'/>
                                                        <Row>
                                                            <Col>
                                                                <h6 className='mt-2 f-14 ln-28'>మీ <b>{Cookies.get("role") == "constituency" ? 'నియోజకవర్గంలో' : Cookies.get("role") == "unit" ? 'యూనిట్ లో' : Cookies.get("role") == "cluster" ? 'క్లస్టర్ లో' : Cookies.get("role") == "booth" ? 'బూత్' : Cookies.get("role") == "Mandal" ? 'మండల్' : '' }</b> భవిష్యతుకు గ్యారంటీ  కార్యక్రమం కోసం : </h6>
                                                                
                                                                <h6 className='mt-3 f-14'>మొత్తం  కుటుంబాలు : <span className='f-14  fw-bold'>{this.state.totalHouses}</span></h6>
                                                                <h6 className='mt-2 f-14'>ఇప్పటివరకు కలిసిన కుటుంబాలు : <span className='f-14  fw-bold'>{this.state.visitedHouses}</span></h6>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                
                                                                {
                                                                    Cookies.get("role") == "booth" ?
                                                                    (
                                                                        <>
                                                                            <h6 className='mt-3 f-14'>ఈ రోజు కలిసిన కుటుంబాలు: <span className='f-14  fw-bold'>{this.state.todaycnt}</span></h6>
                                                                            {
                                                                                this.state.pendingTodayCount >=40 ?
                                                                                ("")
                                                                                :
                                                                                (<h6 className='mt-2 f-14'>ఈ రోజు ఇంకా కలవవలసిన కుటుంబాలు : <span className='f-16  fw-bold color_red'>{this.state.pendingTodayCount}</span></h6>)
                                                                            }
                                                                            
                                                                        </>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                        <h6 className='mt-3 f-14'>ఈ రోజు  కలిసిన కుటుంబాలు : <span className='f-14  fw-bold'>{this.state.todaycnt}</span></h6>
                                                                        <h6 className='mt-2 f-14'>ఈ రోజు ఈ కార్యక్రమంలో పాల్గొన్నవారు  : <span className='f-14  fw-bold'>{this.state.usersCnt}</span></h6>
                                                                        </>
                                                                    )
                                                                }
                                                                
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                    {
                                                        this.state.userAccessList != null && this.state.userAccessList.status === "Success" ?
                                                        (
                                                            <Row>
                                                                <Col>
                                                                        <Link to={{
                                                                                pathname: "/volunteer_page",
                                                                                params: {
                                                                                    navbarDisplay:"hide",
                                                                                    userAccessList:this.state.userAccessList,
                                                                                }
                                                                                
                                                                            }} >
                                                                            <Button variant="outline-danger" className='float-end f-12  fw-bold'>START WITH REGISTER  <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                        </Link>
                                                                    </Col>
                                                                </Row>
                                                        )
                                                        :
                                                        (
                                                            ""
                                                        )
                                                    }
                                                    
                                                </Card.Body>
                                            </Card>
                                            
                                        </Col>
                                    </Row>
                                    )
                                    :
                                    ("")
                                */}
                                {
                                    this.state.roleAccessId.id != "20" && this.state.roleAccessId.id != "17" && this.state.roleAccessId.id != "16" && this.state.roleAccessId.id != "11" && this.state.roleAccessId.id != "15" && Cookies.get("role") != ""  ?
                                    (
                                        <Row>
                                                <Col>
                                                    <Card className='mt-2'>
                                                        <Card.Img variant="top" src={require('../../images/BC.jpeg')} className='img_border_bottom_raduis height_200' />
                                                        <Card.Body className='p-2'>
                                                            <Card.Text>
                                                                <Row>
                                                                    <Col className='fw-bold text_css'>
                                                                    ప్రతి బిసి నాయకుడు మరియు కార్యకర్త ఈ జయహో బీసీ కార్యక్రమం గురించి మీ నియోజకవర్గ ఇంచార్జి తో సమన్వయమై, మీ ప్రాంతంలో వుండే బీసీ కుటుంబాలలో తెలుగుదేశం బిసి లకు ఇస్తున్న ప్రాధాన్యతులను తెలియజేసి ఈ కార్యక్రమంలో బాగస్వామ్యులను చేయండి.
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col className='fw-bold mt-2 f-14'>
                                                                    మీరు పాల్గొనే కార్యక్రమంలో ఫొటోస్ తీసి బాట్ లో అప్లోడ్ చేయండి , అవి మీరు పాల్గొన్న కార్యక్రమాలుగా పార్టీ నందు నమోదు అవుతాయి.
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <Row>
                                                                
                                                                <Col className='text_decoration_none_a'>
                                                                    <Link to={{
                                                                                pathname: "/ongoing_ready_event" ,
                                                                                params: {
                                                                                    navbarDisplay:"hide",
                                                                                    eventStatus: 2,
                                                                                    event:{"campaignId":328,"campaignDetailsId":292066,"campaignName":"జయహో బీసీ","locationScopeId":2,"level":"STATE","location":"Andhra Pradesh","locationId":1,"conductedStatusId":2,"conductedStatus":"ONGOING","subTypeId":0,"subType":"","fromDate":"2024-01-12","todate":"2024-02-24","isAttended":"No","campaignTypeId":7,"surveyType":""},
                                                                                    eventType:"other_programs",
                                                                                    campaignDetailsId:292064,
                                                                                    isAttended:'No',
                                                                                    surveyType:""
                                                                                }
                                                                            }} >
                                                                                
                                                                                <Button variant="outline-danger" className='f-12 fw-bold float-end mt-3' >ఫొటోస్ అప్లోడ్ <i class="fa-solid fa-arrow-right ms-1"></i></Button>
                                                                            </Link>
                                                                </Col>
                                                                
                                                                </Row>
                                                            
                                                        
                                                            
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                    
                                                </Col>
                                            </Row>
                                    )
                                    :
                                    ("")
                                }
                                        
                                
                            
                                
                                    {/*<Row>
                                        <Col>
                                            <Card className='mt-3'>
                                                <img src={require('../../images/cbn_support_1.png')} alt="cbn_support" className='img_pos_support'></img>
                                                <Card.Img variant="top" src={CBNSUPPORT} className='' />
                                                <Card.Body className='p-2'>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className=''>
                                                                <ul className='list-inline hash_tag_css text-center'>
                                                                    <li>#PeopleWithNaidu , </li>
                                                                    <li>#FalseCasesAgainstNaidu</li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                        
                                                        {
                                                            this.state.selectedCampaignDataArr.length>0 ? 
                                                            (
                                                                <Col className='text_decoration_none_a'>
                                                                    {
                                                                        this.state.selectedCampaignDataArr.filter(filterVal =>  filterVal.campaignId == 320).map((item,index) => {
                                                                            return(
                                                                                <Link to={{
                                                                                    pathname: "/ongoing_ready_event" ,
                                                                                    params: {
                                                                                        navbarDisplay:"hide",
                                                                                        eventStatus: item.conductedStatusId,
                                                                                        event:item,
                                                                                        eventType:"other_programs",
                                                                                        campaignDetailsId:item.campaignDetailsId,
                                                                                        isAttended:item.isAttended,
                                                                                        surveyType:item.surveyType
                                                                                    }
                                                                                }} >
                                                                                    <p className='f-14  fw-bold color_red '>బాబుతో నేను - మన నాయకుడు చంద్ర బాబు నాయుడు గారి అక్రమ అరెస్ట్ నిరసన కార్యక్రమంలో పాలుగొన్న ఫోటోలు ప్రతి రోజు ఇక్కడ అప్‌లోడ్ చేయండి.
                                                                                    
                                                                                    <Button variant="danger" className='f-14  fw-bold  mt-4 float-end' >బాబుతో నేను <i class="fa-solid fa-arrow-right ms-1"></i></Button>
                                                                                    </p> 
                                                                                </Link>
                                                                            )
                                                                        })
                                                                    }
                                                                    
                                                                    </Col>
                                                            )
                                                            :
                                                            (
                                                                <Col>
                                                                    <Button variant="outline-danger" className='f-14  fw-bold  mt-2'>బాబుతో నేను - మన నాయకుడు చంద్ర బాబు నాయుడు గారి అక్రమ అరెస్ట్ నిరసన కార్యక్రమంలో పాలుగొన్న ఫోటోలు ప్రతి రోజు ఇక్కడ అప్‌లోడ్ చేయండి <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                </Col>
                                                            )
                                                        }
                                                        
                                                        </Row>
                                                    
                                                
                                                    
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            
                                        </Col>
                                                        </Row>*/}
                                    
                                    <Row>
                                        <Col>
                                            <Card className='mt-2'>
                                                <Card.Img variant="top" src={require('../../images/register_vote.jpg')} className='img_border_bottom_raduis height_200' />
                                                <Card.Body className='p-2'>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className='fw-bold text_css'>
                                                            కొత్త ఓటు కోసం /  ఓట్ హక్కు కలిగి మార్పు కోసం నమోదు చేయటకు : 
                                                            <br/> <b className='f-18'>సందేహాలు - జవాబులు</b>
                                                            </Col>
                                                        </Row>
                                                        
                                                        <Row>
                                                            <Col className='mt-3'>
                                                                <h6 className="ms-4 f-14 ">View PDF :
                                                                    <Link to={{
                                                                                pathname: "/pdf_viewer" ,
                                                                                params: {
                                                                                    navbarDisplay:"hide",
                                                                                    pdf_url:"https://www.mypartydashboard.com/DocFiles/Guidence_Voter_Register_or_Modify_Q_A_For_Booth_Incharges.pdf"
                                                                                }
                                                                            }} className=" fw-bold ms-2 hover_color color_red">
                                                                            సందేహాలు - జవాబులు
                                                                        </Link>
                                                                        
                                                                        </h6>

                                                            </Col>
                                                        </Row>
                                                    
                                                
                                                    
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            
                                        </Col>
                                    </Row>

                                

                                    
                                    
                                    
                                    {/*
                                       this.state.roleAccessId.id != "17" && this.state.roleAccessId.id != "16" && this.state.roleAccessId.id != "11" &&  this.state.roleAccessId.id != "15" && this.state.userAccessList != null && this.state.userAccessList.status === "Success" ? 
                                        (
                                            <>
                                            <Row>
                                                <Col>
                                                    <Card className='mt-3'>
                                                        <Card.Header className='text-center fw-bold bg_color_yellow'>
                                                            <Row>
                                                                <Col>
                                                                    <div className='flex-title'>
                                                                        <Image src={BGLOGO} className=''/>
                                                                        <h6 className='fw-bold text-center ln-28'>భవిష్యత్తుకు&nbsp;గ్యారెంటీ <br/> రిజిస్టేషన్‌ <span className='color_red ml-0 f-18'>గడప గడపకు</span></h6>
                                                                    </div>
                                                                    
                                                                </Col>
                                                                
                                                            </Row>
                                                            
                                                        </Card.Header>
                                                        
                                                        <Card.Body className='p-2'>
                                                            <Card.Text className='fw-bold text_css'>
                                                            భవిష్యతుకు గ్యారెంటీ కార్యక్రమంలో భాగంగా మీరు మీ పరిథిలోని పోలింగ్ బూత్ నందు ఓటర్లని ఈ కార్యక్రమంలో రిజిస్టర్ చేయుటకు ఈ క్రింది లింక్ ని క్లిక్ చేసి నమోదు చేయగలరు
                                                            </Card.Text>
                                                            {
                                                                this.state.userAccessList != null && this.state.userAccessList.status === "Success" ?
                                                                (
                                                                    <Row>
                                                                        <Col>
                                                                                <Link to={{
                                                                                        pathname: "/enrollment_dashboard",
                                                                                        params: {
                                                                                            navbarDisplay:"hide",
                                                                                            volunteerDts:this.state.volunteerDts,
                                                                                            userAccessList:this.state.userAccessList,
                                                                                            accessName:this.state.accessName,
                                                                                            roleName:this.state.roleName
                                                                                        }
                                                                                        
                                                                                    }} >
                                                                                    <Button variant="outline-danger" className='float-end f-12  fw-bold'>VIEW DASHBOARD  <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                                </Link>
                                                                            </Col>
                                                                        <Col>
                                                                                <Link to={{
                                                                                        pathname: "/volunteer_page",
                                                                                        params: {
                                                                                            navbarDisplay:"hide",
                                                                                            userAccessList:this.state.userAccessList,
                                                                                        }
                                                                                        
                                                                                    }} >
                                                                                    <Button variant="outline-danger" className='float-end f-12  fw-bold'>START WITH REGISTER  <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                                </Link>
                                                                            </Col>
                                                                        </Row>
                                                                )
                                                                :
                                                                (
                                                                    <Row>
                                                                        
                                                                            <Col>
                                                                                
                                                                                <a href="https://www.tdpmanifesto.com/" target='_blank'><Button variant="outline-danger" className='float-end f-12  fw-bold'>START WITH REGISTER  <i class="fa-solid fa-arrow-right ms-1"></i></Button></a> 
                                                                                
                                                                            </Col>
                                                                        </Row>
                                                                )
                                                            }
                                                            
                                                        </Card.Body>
                                                    </Card>
                                                
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Card className='mt-2'>
                                                        <Card.Img variant="top" src={require('../../images/upload_image.jpg')} className='img_border_bottom_raduis height_200' />
                                                        <Card.Body className='p-2'>
                                                        <h6 className='fw-bold text-center ln-28 border-bottom-double'>భవిష్యత్తుకు&nbsp;గ్యారెంటీ <span className='color_red ml-0 f-18'>ఫొటోస్ అప్లోడ్</span></h6>
                                                            <Card.Text>
                                                                <Row>
                                                                    <Col className='fw-bold text_css'>
                                                                    మీరు చేసే భవిష్యతు గ్యారంటీ - కార్యక్రమ ఫొటోస్ ఎక్కడ అప్లోడ్ చేసి - మిగతా బూత్ , యూనిట్, క్లస్టర్ ఇన్చార్జిస్ కి స్ఫూర్తి నింపి ఈ కార్యక్రమాన్ని విజయవంతం చేదాం.
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <Row>
                                                                
                                                                {/*
                                                                    this.state.selectedCampaignDataArr.length>0 ? 
                                                                    (
                                                                        <Col className='text_decoration_none_a'>
                                                                            {
                                                                                this.state.selectedCampaignDataArr.filter(filterVal =>  filterVal.campaignId == 326).map((item,index) => {
                                                                                    return(
                                                                                        <Link to={{
                                                                                            pathname: "/ongoing_ready_event" ,
                                                                                            params: {
                                                                                                navbarDisplay:"hide",
                                                                                                eventStatus: item.conductedStatusId,
                                                                                                event:item,
                                                                                                eventType:"other_programs",
                                                                                                campaignDetailsId:item.campaignDetailsId,
                                                                                                isAttended:item.isAttended,
                                                                                                surveyType:item.surveyType
                                                                                            }
                                                                                        }} >
                                                                                            
                                                                                            <Button variant="outline-danger" className='f-12 fw-bold float-end' >ఫొటోస్ అప్లోడ్ <i class="fa-solid fa-arrow-right ms-1"></i></Button>
                                                                                        </Link>
                                                                                    )
                                                                                })
                                                                            }
                                                                            </Col>
                                                                    )
                                                                    :
                                                                    (
                                                                        ""
                                                                    )
                                                                } checkkkk

                                                                <Col className='text_decoration_none_a'>
                                                                    <Link to={{
                                                                                pathname: "/ongoing_ready_event" ,
                                                                                params: {
                                                                                    navbarDisplay:"hide",
                                                                                    eventStatus: 2,
                                                                                    event:{"campaignId":326,"campaignDetailsId":292064,"campaignName":"Bhavshaytuku Guarantee","locationScopeId":2,"level":"STATE","location":"Andhra Pradesh","locationId":1,"conductedStatusId":2,"conductedStatus":"ONGOING","subTypeId":0,"subType":"","fromDate":"2023-11-10","todate":"2024-01-31","isAttended":"No","campaignTypeId":7,"surveyType":""},
                                                                                    eventType:"other_programs",
                                                                                    campaignDetailsId:292064,
                                                                                    isAttended:'No',
                                                                                    surveyType:""
                                                                                }
                                                                            }} >
                                                                                
                                                                                <Button variant="outline-danger" className='f-12 fw-bold float-end' >ఫొటోస్ అప్లోడ్ <i class="fa-solid fa-arrow-right ms-1"></i></Button>
                                                                            </Link>
                                                                </Col>
                                                                
                                                                </Row>
                                                            
                                                        
                                                            
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                    
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <div className='bg_red_logo mt-3'>
                                                        <p className='text_pad_size'>
                                                            మీకు భవిష్యతుకు గ్యారంటీ కార్యక్రమంలో పాల్గొనేటపుడు వచ్చే సందేహాలను తీర్చకొనుట కొరకు ఈ క్రింది డాక్యుమెంట్ ని చదవగలరు. 
                                                        </p>
                                                        <h6 className='color_white fw-bold f-14'> 
                                                        <Link to={{
                                                                                pathname: "/pdf_viewer" ,
                                                                                params: {
                                                                                    navbarDisplay:"hide",
                                                                                    pdf_url:"https://imagesearch-projectkv.s3.amazonaws.com/BG_FAQ.pdf"
                                                                                }
                                                                            }} className="color_white fw-bold ms-2 hover_color">
                                                                            భవిష్యతుకు గ్యారంటీ FAQ  డాక్యుమెంట్...
                                                        </Link>  
                                                        </h6>
                                                    
                                                        
                                                    </div>
                                                    
                                                </Col>
                                            </Row>
                                            </>
                                        )
                                        :
                                        ("")
                                    */}
                                                    
                                    
                                    

                                    {/*
                                        this.state.roleName == "booth" ?
                                        (
                                            <Row>
                                                <Col>
                                                    <Card className='mt-3'>
                                                        <Card.Header className='text-center fw-bold bg_color_yellow'>
                                                            <Row>
                                                                <Col>
                                                                    <div className='flex-title'>
                                                                        <i class="fa-solid fa-people-group f-32"></i>
                                                                        <h6 className='fw-bold text-center ln-28 f-14'>భవిష్యతుకు గ్యారెంటీ కార్యక్రమంలో బూత్‌ స్థాయి కార్యకర్తలు ఇన్‌ఛార్జులు </h6>
                                                                    </div>
                                                                    
                                                                </Col>
                                                                
                                                            </Row>
                                                            
                                                        </Card.Header>
                                                        <Card.Body className='p-0'>
                                                            <ul className='list_voters_info_css mt-3 fw-bold'>
                                                                <li>బూత్‌ స్థాయి క్యాడర్‌ గడప గడపకు వెళ్లి రోజుకు 10 కుటుంబాలను కలిసి 30-40 కుటుంబాల రిజిస్ట్రేషన్లను పూర్తి చేస్తారు.</li> 
                                                                <li>మొత్తం 45 రోజుల్లో దాదాపు 350 కుటుంబాల రిజిస్ట్రేషన్లను పూర్తి చేయాలి.</li> 
                                                            </ul>
                                                        </Card.Body>
                                                    </Card>
                                                
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ('')
                                    }  

                                    {
                                        this.state.roleName == "unit" ?
                                        (
                                            <Row>
                                                <Col>
                                                    <Card className='mt-3'>
                                                        <Card.Header className='text-center fw-bold bg_color_yellow'>
                                                            <Row>
                                                                <Col>
                                                                    <div className='flex-title'>
                                                                        <i class="fa-solid fa-people-group f-32"></i>
                                                                        <h6 className='fw-bold text-center ln-28 f-14'>భవిష్యతుకు గ్యారెంటీ కార్యక్రమంలో యూనిట్‌  ఇన్‌ఛార్జులు ఇన్‌ఛార్జులు </h6>
                                                                    </div>
                                                                    
                                                                </Col>
                                                                
                                                            </Row>
                                                            
                                                        </Card.Header>
                                                        <Card.Body className='p-0'>
                                                            <ul className='list_voters_info_css mt-3 fw-bold'>
                                                                <li>యూనిట్‌ ఇన్‌ఛార్జులు రోజుకు 1 బూత్‌ చొప్పున వరంలో 5 రోజులు పాటు గడప కార్యక్రమం నిర్వహించాలి.</li> 
                                                                <li>మిగిలిన రెండు రోజులు మానిటరింగ్‌ చేస్తూ, కార్యాచరణ లోని లోటుపాట్లని సరిచేసుకోవాలి .</li> 
                                                            </ul>
                                                        </Card.Body>
                                                    </Card>
                                                
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                    }                            
                                    
                                    {
                                        this.state.roleName == "cluster" ?
                                        (
                                            <Row>
                                                <Col>
                                                    <Card className='mt-3'>
                                                        <Card.Header className='text-center fw-bold bg_color_yellow'>
                                                            <Row>
                                                                <Col>
                                                                    <div className='flex-title'>
                                                                        <i class="fa-solid fa-people-group f-32"></i>
                                                                        <h6 className='fw-bold text-center ln-28 f-14'>భవిష్యతుకు గ్యారెంటీ కార్యక్రమంలో క్లస్టర్‌ ఇంఛార్జ్‌ భాద్యతలు </h6>
                                                                    </div>
                                                                    
                                                                </Col>
                                                                
                                                            </Row>
                                                            
                                                        </Card.Header>
                                                        <Card.Body className='p-0'>
                                                            <ul className='list_voters_info_css mt-3 fw-bold'>
                                                                <li>రోజుకు 1 బూత్‌ చొప్పున మొత్తం 45 రోజులలో 25 రోజులు పాటు గడప గడప కార్యక్రమం నిర్వహించాలి.</li> 
                                                                <li>మిగిలిన 20 రోజులుల్లో పార్టీ కార్యకర్తలు (యూనిట్‌ ఇంఛార్జ్‌, బూత్‌ క్యాడర్‌) నిర్వహించే కార్యక్రమాలను మానిటరింగ్‌ చేస్తూ, కార్యాచరణ లోని లోటుపాట్లని సరిచేసుకోవాలి .</li> 
                                                            </ul>
                                                        </Card.Body>
                                                    </Card>
                                                
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                    }
                                
                                    {
                                        this.state.roleName == "constituency" ?
                                        (
                                            <Row>
                                                <Col>
                                                    <Card className='mt-3'>
                                                        <Card.Header className='text-center fw-bold bg_color_yellow'>
                                                            <Row>
                                                                <Col>
                                                                    <div className='flex-title'>
                                                                        <i class="fa-solid fa-people-group f-32"></i>
                                                                        <h6 className='fw-bold text-center ln-28 f-14'>భవిష్యతుకు గ్యారెంటీ కార్యక్రమంలో అసెంబ్లీ నియోజకవర్గం ఇంఛార్జ్‌ భాద్యతలు  </h6>
                                                                    </div>
                                                                    
                                                                </Col>
                                                                
                                                            </Row>
                                                            
                                                        </Card.Header>
                                                        <Card.Body className='p-0'>
                                                            <ul className='list_voters_info_css mt-3 fw-bold'>
                                                                <li>ముందుగా గుర్తించిన కీలకమైన బూత్‌లలో 100 శాతం రిజిస్టేషన్‌ పూరి చేయాలి.</li> 
                                                                <li>45 రోజుల్లో సుమారు 2 లక్షల రిజిస్ట్రేషన్లు అయ్యేలా చూడాలి .</li> 
                                                                <li>మండల, క్లస్టర్‌ స్థాయిలో రిజిస్ట్రేషన్‌ డెస్క్‌లు ఏర్పాటు చేయాలి . (వీలైనంత వరకు యూనిట్‌  స్థాయిలో కూడా ఏర్పాటు చేసుకోవాలి)</li>
                                                                <li>20-25 మంది ప్రొఫెషనల్‌ వాలంటీర్లతో రిజిస్టేషన్‌ _ ప్రక్రియను _ సమర్థవంతంగా నిర్వహించాలి.</li>
                                                            </ul>
                                                        </Card.Body>
                                                    </Card>
                                                
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ("")
                                        */}
                                    <Row>
                                        <Col>
                                            <Card className='mt-2'>
                                            <iframe id="ytplayer" type="text/html" width="100%" height="200"
                                            src="https://www.youtube.com/embed/PvwgMco8qAA?autoplay=0&origin=https://ui.projectkv.com"
                                            frameborder="0" className='border-left-right-radius'/>
                                                <Card.Body className='p-2'>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col>
                                                                <h6 className='f-14'>Share On : </h6>
                                                                <ul class="social-media-icons mt-3 pad_left_0">
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Facebook</Tooltip>}>
                                                                            <a className="fb-color" > <FacebookShareButton url={"https://www.youtube.com/watch?v=PvwgMco8qAA"} title="బాబు ష్యూరిటీ - భవిష్యత్తుకు గ్యారెంటీ  : ">
                                                                                <i class="fab fa-facebook-f icon"></i>
                                                                            </FacebookShareButton></a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Whatsapp</Tooltip>}>
                                                                            <a className="wt-color"> <WhatsappShareButton url={"https://www.youtube.com/watch?v=PvwgMco8qAA" } title="బాబు ష్యూరిటీ - భవిష్యత్తుకు గ్యారెంటీ  : ">
                                                                                <i class="fab fa-whatsapp icon"></i>
                                                                            </WhatsappShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Telegram</Tooltip>}>
                                                                            <a className="te-color"> <TelegramShareButton url={"https://www.youtube.com/watch?v=PvwgMco8qAA"} title="బాబు ష్యూరిటీ - భవిష్యత్తుకు గ్యారెంటీ  : ">
                                                                                <i class="fab fa-telegram icon"></i>
                                                                            </TelegramShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Twitter</Tooltip>}>
                                                                            <a className="tw-color"><TwitterShareButton url={"https://www.youtube.com/watch?v=PvwgMco8qAA" } title="బాబు ష్యూరిటీ - భవిష్యత్తుకు గ్యారెంటీ  : ">
                                                                                <i class="fab fa-twitter icon"></i>
                                                                            </TwitterShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>

                                                                </ul>
                                                            </Col>
                                                            
                                                        </Row>
                                                    
                                                
                                                    
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            
                                        </Col>
                                    </Row> 
                                    <Row>
                                        <Col>
                                            <Card className='mt-2'>
                                            <iframe id="ytplayerId" type="text/html" width="100%" height="200"
                                            src="https://www.youtube.com/embed/sI6eRwmFPic?autoplay=0&origin=https://ui.projectkv.com"
                                            frameborder="0" className='border-left-right-radius'/>
                                                <Card.Body className='p-2'>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col>
                                                                <h6 className='f-14'>Share On : </h6>
                                                                <ul class="social-media-icons mt-3 pad_left_0">
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Facebook</Tooltip>}>
                                                                            <a className="fb-color" > <FacebookShareButton url={"https://www.youtube.com/watch?v=sI6eRwmFPic"} title="EX CM Nara Chandrababu Naidu Emotional Song : ">
                                                                                <i class="fab fa-facebook-f icon"></i>
                                                                            </FacebookShareButton></a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Whatsapp</Tooltip>}>
                                                                            <a className="wt-color"> <WhatsappShareButton url={"https://www.youtube.com/watch?v=sI6eRwmFPic" } title="EX CM Nara Chandrababu Naidu Emotional Song : ">
                                                                                <i class="fab fa-whatsapp icon"></i>
                                                                            </WhatsappShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Telegram</Tooltip>}>
                                                                            <a className="te-color"> <TelegramShareButton url={"https://www.youtube.com/watch?v=sI6eRwmFPic"} title="EX CM Nara Chandrababu Naidu Emotional Song : ">
                                                                                <i class="fab fa-telegram icon"></i>
                                                                            </TelegramShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Twitter</Tooltip>}>
                                                                            <a className="tw-color"><TwitterShareButton url={"https://www.youtube.com/watch?v=sI6eRwmFPic" } title="EX CM Nara Chandrababu Naidu Emotional Song : ">
                                                                                <i class="fab fa-twitter icon"></i>
                                                                            </TwitterShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>

                                                                </ul>
                                                            </Col>
                                                            
                                                        </Row>
                                                    
                                                
                                                    
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className='mt-2'>
                                                <Card.Img variant="top" src={require('../../images/social_media_logo.png')} className='img_border_bottom_raduis' />
                                                <Card.Body className='p-2'>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className='fw-bold text_css'>
                                                            పార్టీ సోషల్ మీడియా ఛానెల్‌లు సబ్‌స్క్రైబ్ చేసుకోండి, లేటెస్ట్ అప్‌డేట్‌లు అందరికి తెలియచేయండి
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className=''>
                                                                <ul className='list-inline hash_tag_css text-center'>
                                                                    <li>#Whatsapp  , </li>
                                                                    <li>#whatsappchannel , </li>
                                                                    <li>#TDP , </li>
                                                                    <li>#NCBN , </li>
                                                                    <li>#NaraLokesh </li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                            <Link to={{
                                                                        pathname: "/social_media",
                                                                        params: {
                                                                            navbarDisplay:"hide"
                                                                        }
                                                                    }} >
                                                                <Button variant="outline-danger" className='f-12  fw-bold float-right'> PARTY SOCIAL MEDIA <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    
                                                
                                                    
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            
                                        </Col>
                                    </Row>
                                    

                                

                                {/* <Row>
                                        <Col>
                                            <Card>
                                                <Card.Img variant="top" src={manifest_logo} />
                                                <Card.Body className='p-2'>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col>
                                                                <h6 className='f-14'>Share On Manifest Details : </h6>
                                                                <ul class="social-media-icons mt-3 pad_left_0">
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Facebook</Tooltip>}>
                                                                            <a className="fb-color" > <FacebookShareButton url={"https://www.mypartydashboard.com/DocFiles/TDP_MANIFEST_PHASE1_DETAILS.pdf"} title="TDP_MANIFEST_PHASE1_DETAILS : ">
                                                                                <i class="fab fa-facebook-f icon"></i>
                                                                            </FacebookShareButton></a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Whatsapp</Tooltip>}>
                                                                            <a className="wt-color"> <WhatsappShareButton url={"https://www.mypartydashboard.com/DocFiles/TDP_MANIFEST_PHASE1_DETAILS.pdf" } title="TDP_MANIFEST_PHASE1_DETAILS : ">
                                                                                <i class="fab fa-whatsapp icon"></i>
                                                                            </WhatsappShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Telegram</Tooltip>}>
                                                                            <a className="te-color"> <TelegramShareButton url={"https://www.mypartydashboard.com/DocFiles/TDP_MANIFEST_PHASE1_DETAILS.pdf"} title="TDP_MANIFEST_PHASE1_DETAILS : ">
                                                                                <i class="fab fa-telegram icon"></i>
                                                                            </TelegramShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>
                                                                    
                                                                    <li>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>share on Twitter</Tooltip>}>
                                                                            <a className="tw-color"><TwitterShareButton url={"https://www.mypartydashboard.com/DocFiles/TDP_MANIFEST_PHASE1_DETAILS.pdf" } title="TDP_MANIFEST_PHASE1_DETAILS : ">
                                                                                <i class="fab fa-twitter icon"></i>
                                                                            </TwitterShareButton>
                                                                            </a>
                                                                        </OverlayTrigger>

                                                                    </li>

                                                                </ul>
                                                            </Col>
                                                            <Col>
                                                                <Link to={{
                                                                        pathname: "/manifest_page",
                                                                        params: {
                                                                            navbarDisplay:"hide",
                                                                        }
                                                                        
                                                                    }} >
                                                                    <Button variant="outline-danger" className='float-end f-12  fw-bold  mt-4'>View Details  <i class="fa-solid fa-arrow-right ms-1"></i></Button> 
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    
                                                
                                                    
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className='mt-3'>
                                            <Card className='border_card_r_5'>
                                                <Card.Header className='text-center fw-bold bg_color_yellow'>సమగ్ర ఓటరు సర్వే</Card.Header>
                                                <Card.Body className='p-2'>
                                                    <Image src={voter_survey_image} thumbnail  className='w-100 cursor_pointer' onClick={this.openModal}></Image>
                                                    <ModalVideo channel='custom' isOpen={this.state.isOpen} url='https://www.mypartydashboard.com/DocFiles/voters_video.mp4' onClose={() => this.setState({isOpen: false})} />      
                                                    
                                                    <Row className='mt-4'>
                                                            <Col>
                                                                <h6 className='f-14 fw-bold'>రాష్ట్ర వ్యాప్తంగా సమగ్ర ఓటరు సర్వే : </h6>
                                                                <ul className='list_voters_info_css'>
                                                                    <li>21-07-2023 నుంచి ఇంటింటికీ రానున్న బీఎల్‌వోలు.</li> 
                                                                    <li>18 ఏళ్లు నిండిన ప్రతి ఒక్కరూ ఓటు హక్కు కలిగేలా చూడడం.</li> 
                                                                    <li>2024 జనవరి 1వ తేదీ నాటికి 18 ఏళ్లు నిండనున్న వారికి ఓటు హక్కు కల్పించటం.</li> 
                                                                </ul>
                                                            </Col>
                                                        </Row> 
                                                    <Row>  
                                                        <Col>              
                                                            <Button variant="outline-danger" className='float-end f-12  fw-bold mt-3' onClick={() => this.votersSurveyBlock()}>Click To View Show More Detailed Information  <i class="fa-solid fa-arrow-right ms-1"></i></Button>                
                                                        </Col>
                                                    </Row>               
                                                {
                                                    this.state.votersSurveyInfoBlock == true ?
                                                    (
                                                        <>
                                                    
                                                        <Row className='mt-2'>
                                                            <Col>
                                                                <h6 className='f-14 fw-bold'> సర్వేలో పరిశీలించే అంశాలు  : </h6>
                                                                <ul className='list_voters_info_css'>
                                                                    <li>ఓటరు జాబితాలో డబుల్‌ ఎంట్రీలు, నకిలీ ఓట్ల గుర్తింపు.</li> 
                                                                    <li>చనిపోయిన వారి ఓట్ల తొలగింపు.</li> 
                                                                    <li>వందేళ్లు వయస్సు పైబడిన వారిని ప్రత్యేకంగా గుర్తించడం.</li> 
                                                                    <li>డోర్‌ నంబర్లు లేని, ఒకే డోర్‌ నంబరుపై పదుల సంఖ్యలో ఉన్న ఓట్లు పరిశీలన.</li> 
                                                                    <li>సర్వీసు ఓటర్లు(మిలటరీ), ఎన్‌ఆర్‌ఐ ఓటర్ల వివరాలను సరిచేయడం.</li> 
                                                                    <li>దీర్ఘ కాలంగా ఇతర ప్రాంతాలకు వలస వెళ్లిన వారి ఓట్లను వారి అభీష్టం మేరకు ఏ ప్రాంతంలో ఉంచాలో కనుక్కుని అక్కడి జాబితాలో ఉంచడం.</li> 
                                                                    <li>ఒక బూత్‌లో సరాసరి 1,500 ఓట్లకు మించి ఉంటే కొత్త బూత్‌కు సిఫార్సు చేయడం.</li> 
                                                                    <li>పేర్లు, నియోజకవర్గాలు, చిరునామాలు, ఫోన్‌ నంబర్లు, ఫొటో గుర్తింపు కార్డులు మార్పులు, చేర్పులు తప్పుఒప్పులు సరిచేయడం..</li> 
                                                                    <li>ఓటర్ల అభ్యర్థన మేరకు ఒక చోటు నుంచి మరో చోటుకు మార్చడం.</li> 
                                                                    <li>గుర్తింపు కార్డులు, జాబితాల్లోని తప్పుల సవరణ.</li> 
                                                                    <li>స్పష్టత లేని ఫొటోలు తొలగించి, మంచి ఫోటోల ఏర్పాటు. అనంతరం జిల్లా ఎన్నికల అధికారికి పంపించడం.</li> 
                                                                    <li>కొత్తగా ఓటు హక్కు పొందని వారుంటే వారు ఓటు హక్కు పొందవచ్చు.</li> 
                                                                    <li>నియోజకవర్గం మారినా, ఓటరు జాబితాలో పేర్లు లేకపోయినా, తప్పులున్నా సరిచేసుకునే అవకాశం ఉంది.</li> 
                                                                </ul>
                                                            </Col>
                                                        </Row> 
                                                        <hr className='mb-3 mt-0'/> 
                                                        <Row className='mt-2'>
                                                            <Col>
                                                                <h6 className='f-14 fw-bold'>  సమగ్ర ఓటరు సర్వే షెడ్యూల్‌   : </h6>
                                                                <ul className='list_voters_info_css'>
                                                                    <li>ఈ నెల 21 నుంచి ఆగస్టు 21 వరకు ఇంటింటికీ బూత్‌ లెవల్‌ అధికారుల సర్వే.</li> 
                                                                    <li>అక్టోబర్ 17వ తేదీన ఇంటిగ్రేటెడ్ డ్రాఫ్ట్ ఎలక్టోరల్ రోల్స్ ప్రచురణ.</li> 
                                                                    <li>వాటిపై క్లెయిమ్‌లు, అభ్యంతరాలు నవంబరు 30 వరకు స్వీకరణ .</li> 
                                                                    <li>తుది జాబితాలు 2024 జనవరి 5 ప్రచురణ.</li> 
                                                                
                                                                </ul>
                                                            </Col>
                                                        </Row> 
                                                        </>
                                                    )
                                                    :
                                                    ("")
                                                }

                                                            
                                                </Card.Body>
                                            </Card>
                                            
                                        </Col>
                                    </Row>*/}
                                    

                                    <Row>
                                        <Col>
                                            <div className='bg_ntr_trust_logo_white mt-2'>
                                                <Image src={MANATDP} className="w-100" alt="MANATDP"></Image> 
                                            
                                                <a href="https://play.google.com/store/apps/details?id=com.manatdp.itdp&hl=en_IN&gl=US" target="_blank">  <Image src={PLAYSTORE} className="mt-3" alt="PLAYSTORE"></Image>   </a>
                                                <a href="https://apps.apple.com/in/app/mana-tdp/id1607830839" target="_blank" className='ms-3'>  <Image src={IPHONE} className="mt-3" alt="PLAYSTORE"></Image>   </a>     
                                            </div>
                                            
                                        </Col>
                                    </Row>               
                                    
                                    <Row>
                                        <Col>
                                            <div className='bg_red_logo mt-3'>
                                                <Image src={LOGORED} className="w-100" alt="LOGORED"></Image> 
                                                <Image src={LOGOREDTEXT} className="w-100 mt-3" alt="LOGOREDTEXT"></Image>
                                                <Row>
                                                    <Col>
                                                        <ul class="social-media-icons mt-2">
                                                            <li className="align_top  mt-2 f-14 color_white">Share On</li>
                                                            <li>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>share on Facebook</Tooltip>}>
                                                                    <a className="fb-color" > <FacebookShareButton url={"https://www.mypartydashboard.com/DocFiles/Jaganasura_Raktha_Charithra.pdf"} title="Jaganasura_Raktha_Charithra : ">
                                                                        <i class="fab fa-facebook-f icon"></i>
                                                                    </FacebookShareButton></a>
                                                                </OverlayTrigger>

                                                            </li>
                                                            <li>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>share on Whatsapp</Tooltip>}>
                                                                    <a className="wt-color"> <WhatsappShareButton url={"https://www.mypartydashboard.com/DocFiles/Jaganasura_Raktha_Charithra.pdf" } title="Jaganasura_Raktha_Charithra : ">
                                                                        <i class="fab fa-whatsapp icon"></i>
                                                                    </WhatsappShareButton>
                                                                    </a>
                                                                </OverlayTrigger>

                                                            </li>
                                                            <li>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>share on Telegram</Tooltip>}>
                                                                    <a className="te-color"> <TelegramShareButton url={"https://www.mypartydashboard.com/DocFiles/Jaganasura_Raktha_Charithra.pdf"} title="Jaganasura_Raktha_Charithra : ">
                                                                        <i class="fab fa-telegram icon"></i>
                                                                    </TelegramShareButton>
                                                                    </a>
                                                                </OverlayTrigger>

                                                            </li>
                                                            
                                                            <li>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>share on Twitter</Tooltip>}>
                                                                    <a className="tw-color"><TwitterShareButton url={"https://www.mypartydashboard.com/DocFiles/Jaganasura_Raktha_Charithra.pdf" } title="Jaganasura_Raktha_Charithra : ">
                                                                        <i class="fab fa-twitter icon"></i>
                                                                    </TwitterShareButton>
                                                                    </a>
                                                                </OverlayTrigger>

                                                            </li>

                                                        </ul>
                                                        <h6 className="ms-4 f-14 color_white">View PDF :
                                                            <Link to={{
                                                                        pathname: "/pdf_viewer" ,
                                                                        params: {
                                                                            navbarDisplay:"hide",
                                                                            pdf_url:"https://www.mypartydashboard.com/DocFiles/Jaganasura_Raktha_Charithra.pdf"
                                                                        }
                                                                    }} className="color_white fw-bold ms-2 hover_color">
                                                                    Jaganasura Raktha Charithra
                                                                </Link>
                                                                
                                                                </h6>

                                                    </Col>
                                                </Row>
                                                
                                            </div>
                                            
                                        </Col>
                                    </Row>

                                    
                                <Row>
                                        <Col>
                                            <div className='bg_ntr_trust_logo mt-3'>
                                                <Image src={NTRTRUSTLOGO} className="w-100" alt="NTRTRUSTLOGO"></Image> 
                                            
                                                <a href="https://play.google.com/store/apps/details?id=com.nutriful.user&hl=en_IN&gl=US&pli=1" target="_blank">  <Image src={PLAYSTORE} className="mt-3" alt="PLAYSTORE"></Image>   </a>     
                                                <a href="https://apps.apple.com/tc/app/nutriful/id1596912420" target="_blank" className='ms-3'>  <Image src={IPHONE} className="mt-3" alt="PLAYSTORE"></Image>   </a>     
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </>
                        )
                    }
                    
                    
                        
                </Container> 
                
                
            </section>
        )
    }
  }
  
  export default withRouter(Dashboard);
  