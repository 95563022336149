
import React ,{ Component } from 'react';
import {Container, Row, Col,Button,Image,Form,OverlayTrigger,Tooltip } from 'react-bootstrap';
import "./EventsAndActivities.css";
import arrowLeft from '../../images/arrow-left.png';
import { withRouter,Link } from "react-router-dom";
import { getCampaignAPICallData } from '../../services/MemberService';
import loader from '../../images/loader.gif';
import CardLessDataTableWithSearch from '../datatables/CardLessDataTableWithSearch';
import UpdateMLCAttributesSavingModal from './UpdateMLCAttributesSavingModal';

class MLCVotersSection extends Component {
  constructor(props) {
      super(props)
      this.state = {
        event:props?.location?.params?.event == undefined ? "" : props?.location?.params?.event,
        eventStatus:props?.location?.params?.eventStatus == undefined ? "" : props?.location?.params?.eventStatus,
        campaignDetailsId:props?.location?.params?.campaignDetailsId == undefined ? "" : props?.location?.params?.campaignDetailsId,
        eventType:props?.location?.params?.eventType == undefined ? "" : props?.location?.params?.eventType,
        isAttended:props?.location?.params?.isAttended == undefined ? "" : props?.location?.params?.isAttended,
        surveyType:props?.location?.params?.surveyType == undefined ? "" : props?.location?.params?.surveyType,
        pollManagementPage:props?.location?.params?.pollManagementPage == undefined ? "" : props?.location?.params?.pollManagementPage,
        MLCPage:props?.location?.params?.MLCPage == undefined ? "" : props?.location?.params?.MLCPage,
        statusTypeVoter:"",
        MLCvoterType:'CUBS',
        MLC_clusterNames:[],
        MLC_unitNames:[],
        MLC_boothList:[],
        MLC_MandalNames:[],
        MLC_VillageNames:[],
        MLC_StreetNames:[],
        clusterId:"",
        unitId:"",
        boothId:"",
        mandalId:"",
        villageId:"",
        villageCheckLoader:0,
        streetId:"",
        streetCheckLoader:0,
        streetCheckMessage:0,
        MLCVotersListData:[],
        MLCVotersListCheck:0,
        votersListCheck:0,
        MLCVotersListCheckLoderSymbol:"hide",
        selectedVoterListTypeName:"",
        validateReason:"",
        villageName:"",
        streetName:"",
        MandalView_locationScopeId:0,
        locationSopeId:0,
        validateReasonForVillage:"",
        visitedVoterLoadingSymbol:"hide",
        visitedMessageDisplay:"",
        visitedSelectedVoterId:"",
        cubsLocationScopeId:0,
        cubsLocationId:0,
        updateMLCVoterAttributesModal:false,
        updateVoterId:"",
        updateVoterDetails:"",
        updateVoterCardNo:"",
        transportStatus:"",
        caste:"",
        party:"",
        mobileNo:"",
        validateUpdateMobileNo:"",
        validateUpdateParty:"",
        validateUpdateCaste:"",
        validateUpdateTransportStatus:"",
        validateUpdateVoterCardNo:"",
        newMemberAddedMsg:"",
        voterSlipGiven:"",
        demoBalletGiven:"",
        validateUpdateVoterSlipGiven:"",
        validateUpdateDemoBalletGiven:"",
        visitLoaderSymbol:"hide",
        MLC_BoothLoc:[],
        MLCBoothLocId:"",
        MLCBoothLocName:"",
        validateReasonMLCBoothLoc:""
      }

      
  }

  componentDidMount() {
    this.getSubLocationsByUserAccess(17);
    this.getSubLocationsByUserAccess(18);
    this.getSubLocationsByUserAccess(9);
    this.getMandalTownsByUserAccess();
   
  }

  selectStatusType = (statusType,value) => {
    this.setState({MLC_clusterNames:[],MLC_unitNames:[],MLC_boothList:[],clusterId:"",
                unitId:"",
                boothId:"",
                mandalId:"",
                villageId:"",
                villageCheckLoader:0,
                streetId:"",
                streetCheckLoader:0,
                streetCheckMessage:0,
                MLCVotersListData:[],
                MLCVotersListCheck:0,
                votersListCheck:0,
                MLCVotersListCheckLoderSymbol:"hide",
                selectedVoterListTypeName:"",
                validateReason:"",
                villageName:"",
                streetName:"",
                MandalView_locationScopeId:0,
                locationSopeId:0,
                validateReasonForVillage:"",
                visitedVoterLoadingSymbol:"hide",
                visitedMessageDisplay:"",
                visitedSelectedVoterId:"",
                cubsLocationScopeId:0,
                cubsLocationId:0,
                updateMLCVoterAttributesModal:false,
                updateVoterId:"",
                updateVoterDetails:"",
                updateVoterCardNo:"",
                transportStatus:"",
                caste:"",
                party:"",
                mobileNo:"",
                validateUpdateMobileNo:"",
                validateUpdateParty:"",
                validateUpdateCaste:"",
                validateUpdateTransportStatus:"",
                validateUpdateVoterCardNo:"",
                newMemberAddedMsg:"",
                voterSlipGiven:"",
                demoBalletGiven:"",
                validateUpdateVoterSlipGiven:"",
                validateUpdateDemoBalletGiven:"",
                visitLoaderSymbol:"hide",
                MLC_BoothLoc:[],
                MLCBoothLocId:"",
                MLCBoothLocName:"",
                validateReasonMLCBoothLoc:""
              });
              
    this.setState({statusTypeVoter:value});

    this.getSubLocationsByUserAccess(17);
    this.getSubLocationsByUserAccess(18);
    this.getSubLocationsByUserAccess(9);
    this.getMandalTownsByUserAccess();
  }

  selectedVoterType = (value) => {
    this.setState({MLC_clusterNames:[],MLC_unitNames:[],MLC_boothList:[],clusterId:"",
                  unitId:"",
                  boothId:"",
                  mandalId:"",
                  villageId:"",
                  villageCheckLoader:0,
                  streetId:"",
                  streetCheckLoader:0,
                  streetCheckMessage:0,
                  MLCVotersListData:[],
                  MLCVotersListCheck:0,
                  votersListCheck:0,
                  MLCVotersListCheckLoderSymbol:"hide",
                  selectedVoterListTypeName:"",
                  validateReason:"",
                  villageName:"",
                  streetName:"",
                  MandalView_locationScopeId:0,
                  locationSopeId:0,
                  validateReasonForVillage:"",
                  visitedVoterLoadingSymbol:"hide",
                  visitedMessageDisplay:"",
                  visitedSelectedVoterId:"",
                  cubsLocationScopeId:0,
                  cubsLocationId:0,
                  updateMLCVoterAttributesModal:false,
                  updateVoterId:"",
                  updateVoterDetails:"",
                  updateVoterCardNo:"",
                  transportStatus:"",
                  caste:"",
                  party:"",
                  mobileNo:"",
                  validateUpdateMobileNo:"",
                  validateUpdateParty:"",
                  validateUpdateCaste:"",
                  validateUpdateTransportStatus:"",
                  validateUpdateVoterCardNo:"",
                  newMemberAddedMsg:"",
                  voterSlipGiven:"",
                  demoBalletGiven:"",
                  validateUpdateVoterSlipGiven:"",
                  validateUpdateDemoBalletGiven:"",
                  visitLoaderSymbol:"hide",
                  MLC_BoothLoc:[],
                  MLCBoothLocId:"",
                  MLCBoothLocName:"",
                  validateReasonMLCBoothLoc:""
                });

   
    if(value == "CUBS"){
      this.getSubLocationsByUserAccess(17);
      this.getSubLocationsByUserAccess(18);
      this.getSubLocationsByUserAccess(9);
    }else if(value == "mandal"){
      this.getMandalTownsByUserAccess();
    }else{
        this.getMlcBoothsInAssemblyConstituency();
    }

    
    this.setState({MLCvoterType:value});
  }
  

  getMlcBoothsInAssemblyConstituency = () => {
    // this.setState({MLC_clusterNames:[],MLC_unitNames:[],MLC_boothList:[]});
        const userAccessObj =  
        JSON.stringify({ 
            "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
            
        });
        

    getCampaignAPICallData(userAccessObj,"getMlcBoothsInAssemblyConstituency")
        .then((res) => res.json())
        .then((data) => { 
            if(data !=null && data.length>0){
                this.setState({MLC_BoothLoc:data});
            }else{
                this.setState({MLC_BoothLoc:[]});
            }
            
        });
  }

  getSubLocationsByUserAccess = (subLocationScopeId) => {
   // this.setState({MLC_clusterNames:[],MLC_unitNames:[],MLC_boothList:[]});
    const userAccessObj =  
        JSON.stringify({ 
            "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
            "subLocationScopeId":subLocationScopeId,
            
        });
         

    getCampaignAPICallData(userAccessObj,"getSubLocationsByUserAccess")
        .then((res) => res.json())
        .then((data) => { 
            if(subLocationScopeId == 17){
              if(data !=null && data.length>0){
                this.setState({MLC_clusterNames:data});
              }else{
                  this.setState({MLC_clusterNames:[]});
              }
            }else if(subLocationScopeId == 18){
              if(data !=null && data.length>0){
                this.setState({MLC_unitNames:data});
              }else{
                  this.setState({MLC_unitNames:[]});
              }
            }else if(subLocationScopeId == 9){
              if(data !=null && data.length>0){
                this.setState({MLC_boothList:data});
              }else{
                  this.setState({MLC_boothList:[]});
              }
            }
            
         });
  }


  
  getMandalTownsByUserAccess = () => {
    this.setState({MLC_MandalNames:[],MLC_StreetNames:[],MLC_VillageNames:[]});
    const userAccessObj =  
        JSON.stringify({ 
            "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
            
        });
         

    getCampaignAPICallData(userAccessObj,"getMandalTownsByUserAccess")
        .then((res) => res.json())
        .then((data) => { 
          if(data !=null && data.length>0){
            this.setState({MLC_MandalNames:data});
          }else{
              this.setState({MLC_MandalNames:[]});
          }
            
         });
  }
  
  getVillagesForMLCVoters = (locationScopeId,locationId) => {
    this.setState({MLC_VillageNames:[]});
    this.setState({villageCheckLoader:1});
    const userAccessObj =  
        JSON.stringify({ 
            "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
            "locationScopeId" : locationScopeId,
            "locationId" : parseInt(locationId)
            
        });
         

    getCampaignAPICallData(userAccessObj,"getVillagesForMLCVoters")
        .then((res) => res.json())
        .then((data) => { 
          if(data !=null && data.length>0){
            this.setState({MLC_VillageNames:data,villageCheckLoader:0});
          }else{
              this.setState({MLC_VillageNames:[],villageCheckLoader:0});
          }
            
         });
  }
  
  getStreetsForMLCVoters = (locationScopeId,villageName) => {
    this.setState({MLC_StreetNames:[]});
    this.setState({streetCheckLoader:1});
    const userAccessObj =  
        JSON.stringify({ 
            "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
            "locationScopeId" : locationScopeId,
            "locationId" : this.state.mandalId,
            "village" : villageName.replace(/ *\([^)]*\) */g, "").trim()
            
        });
         

    getCampaignAPICallData(userAccessObj,"getStreetsForMLCVoters")
        .then((res) => res.json())
        .then((data) => { 
          if(data !=null && data.length>0){
            this.setState({MLC_StreetNames:data,streetCheckLoader:0,streetCheckMessage:0});
          }else{
              this.setState({MLC_StreetNames:[],streetCheckLoader:0,streetCheckMessage:1});
          }
            
         });
  }

  

  handleOnchangeSelectBoxForMandal = (e,type) => {
    this.setState({MLCVotersListData:[],MLCVotersListCheck:0,MLCVotersListCheckLoderSymbol:"hide",
    votersListCheck:0,streetCheckMessage:0,validateReason:"",validateReasonForVillage:""});

    const name = e.target.name;
    const value = e.target.value;
    var index = e.target.selectedIndex;

    if(type == "mandal"){
      this.setState({ mandalId: value });
      setTimeout(() => {
        var mandalNameSplit = e.target[index].text.split(" ");
        if(mandalNameSplit[1] == "Town"){
          this.setState({ MandalView_locationScopeId: 7 });
          this.getVillagesForMLCVoters(7,value);
        }else{
          this.setState({ MandalView_locationScopeId: 5});
          this.getVillagesForMLCVoters(5,value);
        }
  
        
      }, 500);

    }else if(type == "village"){
      this.setState({ villageId: value,villageName:e.target[index].text,locationSopeId:6,validateReasonForVillage:""});
      this.getStreetsForMLCVoters(5,e.target[index].text);
    }else if(type == "street"){
      this.setState({ streetId: value,streetName:e.target[index].text,locationSopeId:0,streetCheckMessage:0});
    }
  }

  handleOnchangeSelectBoxForCubs = (e,type) => {
    const name = e.target.name;
    const value = e.target.value;
    var index = e.target.selectedIndex;

    
      
    if(type == "cluster"){
      this.setState({MLC_unitNames:[],MLC_boothList:[],selectedVoterListTypeName:e.target[index].text,cubsLocationScopeId:17,cubsLocationId:value});
      this.getSubLocationsByUserAccess(18);
      this.getSubLocationsByUserAccess(9);
      this.getMlcVoterDetailsForCampaignSurvey(17,value);
    }else if(type == "unit"){
      this.setState({MLC_clusterNames:[],MLC_boothList:[],selectedVoterListTypeName:e.target[index].text,cubsLocationScopeId:18,cubsLocationId:value});
      this.getSubLocationsByUserAccess(17);
      this.getSubLocationsByUserAccess(9);
      this.getMlcVoterDetailsForCampaignSurvey(18,value);
    }else if(type == "booth"){
      this.setState({MLC_clusterNames:[],MLC_unitNames:[],selectedVoterListTypeName:e.target[index].text,cubsLocationScopeId:9,cubsLocationId:value});
      this.getSubLocationsByUserAccess(17);
      this.getSubLocationsByUserAccess(18);
      
      this.getMlcVoterDetailsForCampaignSurvey(9,value);
    }
    //this.setState({ [name]: value });

    
    
  }

  handleOnchangeSelectBoxForMLCBooth = (e,type) => {
    
    const name = e.target.name;
    const value = e.target.value;
    var index = e.target.selectedIndex;
    this.setState({ MLCBoothLocId: value,MLCBoothLocName:e.target[index].text,locationSopeId:20,selectedVoterListTypeName:e.target[index].text});
    this.getMlcVoterDetailsForCampaignSurvey(20,value);
  }


  getMlcVoterDetailsForCampaignForMLCBooth = (locationScopeId,locationId) => {
    const userAccessObj =  
            JSON.stringify({ 
            "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
            "locationScopeId" : locationScopeId,
            "locationId" : locationId,
              
            });


    getCampaignAPICallData(userAccessObj,"getMlcVoterDetailsForPoll")
        .then((res) => res.json())
        .then((data) => { 
            this.setState({MLCVotersListCheckLoderSymbol:"hide"});
            if(data !=null && data.length>0){
                this.setState({MLCVotersListData:data,votersListCheck:0});
            }else{
                this.setState({MLCVotersListData:[],votersListCheck:1});
            }
    });
            
       
}



  getMlcVoterDetailsForCampaignSurvey = (locationScopeId,locationId) => {
    this.setState({MLCVotersListData:[],MLCVotersListCheck:1,MLCVotersListCheckLoderSymbol:"show",votersListCheck:0});
    const userAccessObj =  
        JSON.stringify({ 
            "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
            "campaignId" : this.state.event.campaignId,
            "locationScopeId" : locationScopeId,
            "locationId" : locationId,
            "status" : this.state.statusTypeVoter
            
        });
         

    getCampaignAPICallData(userAccessObj,"getMlcVoterDetailsForCampaignSurvey")
        .then((res) => res.json())
        .then((data) => { 
          
          this.setState({MLCVotersListCheck:0,MLCVotersListCheckLoderSymbol:"hide"});

         if(data !=null && data.length>0){
          this.setState({MLCVotersListData:data,votersListCheck:0});
         }else{
          this.setState({MLCVotersListData:[],votersListCheck:1});
         }
            
        });
  }


  
  getMLCVotersForMandalView = () => {
    this.setState({validateReason:"" });

      if(this.state.mandalId == ""){
        this.setState({validateReason:"Please Select Mandal" });
        return;
      }
      else if(this.state.villageId == ""){
        this.setState({validateReasonForVillage:"Please Select Village" });
        return;
      }

      this.getMlcVoterDetailsForCampaignSurveyForMandalView();
  }

  
  getMlcVoterDetailsForCampaignSurveyForMandalView = () => {
    this.setState({MLCVotersListData:[],MLCVotersListCheck:1,MLCVotersListCheckLoderSymbol:"show",votersListCheck:0});

    let userAccessObj;

    if(this.state.locationSopeId == 6){
        if(this.state.MandalView_locationScopeId == 5){
          userAccessObj =  
            JSON.stringify({ 
                "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
                "campaignId" : this.state.event.campaignId,
                "locationScopeId" : 6,
                "tehsilId" : this.state.mandalId,
                "village" : this.state.villageName.replace(/ *\([^)]*\) */g, "").trim(),
                "status" : this.state.statusTypeVoter
                
                
            });
      }else if(this.state.MandalView_locationScopeId == 7){
          userAccessObj =  
            JSON.stringify({ 
              "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
              "campaignId" : this.state.event.campaignId,
              "locationScopeId" : 6,
              "townId" : this.state.mandalId,
              "village" : this.state.villageName.replace(/ *\([^)]*\) */g, "").trim(),
              "status" : this.state.statusTypeVoter
                
            });
      }
    }else{
        if(this.state.MandalView_locationScopeId == 5){
          userAccessObj =  
            JSON.stringify({ 
                "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
                "campaignId" : this.state.event.campaignId,
                "locationScopeId" : 0,
                "tehsilId" : this.state.mandalId,
                "village" : this.state.villageName.replace(/ *\([^)]*\) */g, "").trim(),
                "streetName" :this.state.streetName.replace(/ *\([^)]*\) */g, "").trim(),
                "status" : this.state.statusTypeVoter
                
                
            });
      }else if(this.state.MandalView_locationScopeId == 7){
          userAccessObj =  
            JSON.stringify({ 
              "chatId" : window.User !=undefined ? window.User.chatID : "943827154",
              "campaignId" : this.state.event.campaignId,
              "locationScopeId" : 0,
              "townId" : this.state.mandalId,
              "village" : this.state.villageName.replace(/ *\([^)]*\) */g, "").trim(),
              "streetName" : this.state.streetName.replace(/ *\([^)]*\) */g, "").trim(),
              "status" : this.state.statusTypeVoter
                
            });
      }
    }
    
 

    getCampaignAPICallData(userAccessObj,"getMlcVoterDetailsForCampaignSurvey")
        .then((res) => res.json())
        .then((data) => { 
          
          this.setState({MLCVotersListCheck:0,MLCVotersListCheckLoderSymbol:"hide"});

         if(data !=null && data.length>0){
          this.setState({MLCVotersListData:data,votersListCheck:0});
         }else{
          this.setState({MLCVotersListData:[],votersListCheck:1});
         }
            
        });
  }

  MLCvotersDataColumns() {

    let columns = [
          {
            dataField: "serialNo",
            text: "serial No",
            sort: true,
            
        },
        {
            dataField: "VoterName",
            text: "Voter Name",
            sort: true,
            
        },
        {
            dataField: "Gender",
            text: "Gender / Age",
            sort: true,
            
        },
        {
            dataField: "MobileNo",
            text: "Mobile No",
            sort: true,
           
        },
        {
          dataField: "Action",
          text: "Action",
          formatter: this.actionsFormatterForVoter.bind(this),
      },
        
        
        
    ];
    return columns;
}

actionsFormatterForVoter = (cell, row, rowIndex, formatExtraData) => {
  return (
    <>
      <Row>
        <Col sm={12}>
          <div className='flex_block_MLC'>
              <OverlayTrigger key={"top"} placement={"top"}
                  overlay={
                    <Tooltip id={`tooltio-link-add-new-voter`}>
                      <h6 className='f-14'>Visit Voter</h6>
                    </Tooltip>
                  }
                >
                  <>
                  {
                    row.status == 'N' ?
                    (<i class="fa-solid fa-eye color_red cursor_pointer f-14" onClick={(e) => this.insertCampaignVoterSurvey(e,row.voterId)}></i>)
                    :
                    (<i class="f-14 fa-solid fa-circle-check color_green"></i>)
                  }
                  
                  </>
              </OverlayTrigger>
              <OverlayTrigger key={"top"} placement={"top"}
                overlay={
                  <Tooltip id={`tooltio-link-add-new-voter`}>
                    <h6 className='f-14'>Update Voter</h6>
                  </Tooltip>
                }
              >
                {
                  row.isUpdated == 'N' ?
                  (<i class="fa-solid fa-circle-info cursor_pointer f-14" onClick={(e) => this.updateMLCVoterDetails(e,row.voterId)}></i>)
                  :
                  (<i class="fa-solid fa-circle-info color_green cursor_pointer f-14" onClick={(e) => this.updateMLCVoterDetails(e,row.voterId)}></i>)
                }
                
                </OverlayTrigger>
          </div>
        </Col>
        
      </Row>
     
       
      </>
  );
  
}

MLCvotersData = () => {
  let MLCVotersDataArr = [];
      if(this.state.MLCVotersListData.length>0){
          for (let i = 0; i < this.state.MLCVotersListData.length; i++) {
              const obj = {
                serialNo:this.state.MLCVotersListData[i].serialNo,
                VoterName: this.state.MLCVotersListData[i].voterName,
                Gender: this.state.MLCVotersListData[i].gender+"/"+this.state.MLCVotersListData[i].age,
                MobileNo: this.state.MLCVotersListData[i].mobileNo,
                voterId: this.state.MLCVotersListData[i].voterId,
                status: this.state.MLCVotersListData[i].status,
                isUpdated: this.state.MLCVotersListData[i].isUpdated
              }
              MLCVotersDataArr.push(obj);
          } 

          
          
          return MLCVotersDataArr;
      }
}

  insertCampaignVoterSurvey = (e,voter_id) => {
    var voterIdString = voter_id.toString();
    this.setState({visitedVoterLoadingSymbol:"show",visitedSelectedVoterId:voterIdString,visitLoaderSymbol:"show"});
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( position => {
           const getCoordinates =  
            { 
              "latitude" : position.coords.latitude,
              "longitude" : position.coords.longitude,
            }

            const userAccessObj =  
              JSON.stringify({ 
                  "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
                  "voterId" : parseInt(voterIdString),
                  "campaignId" : this.state.event.campaignId,
                  "latitude" : getCoordinates.latitude,
                  "longitude":getCoordinates.longitude
                  
              });
              

          getCampaignAPICallData(userAccessObj,"insertCampaignVoterSurvey")
              .then((res) => res.json())
              .then((data) => { 
                this.setState({visitedVoterLoadingSymbol:"hide",visitLoaderSymbol:"hide"});
                if(data !=null && data.status == "Success"){
                  this.setState({visitedMessageDisplay:"Visited SuccessFully..."});
                  setTimeout(() => {
                    let new_updated_data = this.state.MLCVotersListData.map((item) => {
                        if (item.voterId == voterIdString) {
                            return {
                                ...item,
                                status: "Y",
                            };
                        }
                        return item;
                    });
                    this.setState({MLCVotersListData:new_updated_data,visitedMessageDisplay:""})
                }, 1000);

                  /*setTimeout(() => {
                    if(this.state.MLCvoterType == "CUBS"){
                      this.getMlcVoterDetailsForCampaignSurvey(this.state.cubsLocationScopeId,this.state.cubsLocationId);
                    }else{
                      this.getMlcVoterDetailsForCampaignSurveyForMandalView();
                    }
                    
                    this.setState({visitedMessageDisplay:""});
                }, 1000);*/
                }else{
                  this.setState({visitedMessageDisplay:"Some Thing Wrong,Please Check once"});
                }
                  
              });

        });
      }

   
  }


  updateMLCVoterDetails = (e,voter_id) => {
    
    this.getMlcVoterAttributeDetails(voter_id);
  }

  getMlcVoterAttributeDetails = (voter_id) => {
    const userAccessObj =  
        JSON.stringify({ 
            "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
            "voterId":voter_id.toString(),
        });
         

    getCampaignAPICallData(userAccessObj,"getMlcVoterAttributeDetails")
        .then((res) => res.json())
        .then((data) => { 
          this.setState({updateMLCVoterAttributesModal:true});
          if(data !=null){
            this.setState({updateVoterDetails:data});
            if(this.state.updateVoterDetails !=""){
              this.setState({updateVoterCardNo:this.state.updateVoterDetails.voterCardNo});
              this.setState({mobileNo:this.state.updateVoterDetails.mobileNo});
            }
          }else{
            this.setState({updateVoterDetails:""});
          }  
         });
  }

  handleOnchangeSetUpdateVoterDts = (e,type) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  }



  UpdateMlcVoterAttributes = (e,voter_id) => {
    this.setState({validateUpdateVoterCardNo:"",validateUpdateTransportStatus:"",validateUpdateCaste:"",validateUpdateParty:"",validateUpdateMobileNo:""})
    console.log(this.state.updateVoterCardNo)
    console.log(this.state.transportStatus)
    console.log(this.state.caste)
    console.log(this.state.party)
    console.log(this.state.mobileNo)

    

      
    if(this.state.updateVoterCardNo == ""){
        this.setState({validateUpdateVoterCardNo:"Please Enter Voter Card No" });
        return;
    }

   
    if(this.state.updateVoterCardNo.match(' ')){
      this.setState({validateUpdateVoterCardNo:"Please Enter Valid Voter Card No(remove space)" });
      return;
    }

    if(this.state.updateVoterCardNo.length <=9){
      this.setState({validateUpdateVoterCardNo:"Please Enter Valid Voter Card No" });
      return;
    }

    if(this.state.transportStatus == ""){
      this.setState({validateUpdateTransportStatus:"Please Select Transport Status" });
      return;
  }
    
  if(this.state.caste == ""){
      this.setState({validateUpdateCaste:"Please Select Caste" });
      return;
  }
  if(this.state.party == ""){
    this.setState({validateUpdateCaste:"Please Select Party" });
    return;
  }

  if(this.state.mobileNo == ""){
    this.setState({validateUpdateMobileNo:"Please Enter Mobile No" });
    return;
  }

  if(!this.state.mobileNo.match(/^[0-9]+$/)){
    this.setState({validateUpdateMobileNo:"Please Enter Valid Mobile No" });
    return;
  }
    

    const addNewVoterObj =  
      JSON.stringify({ 
        "chatId" :window.User !=undefined ? window.User.chatID : "943827154",
        "voterId" : voter_id,
        "mobileNo" :this.state.mobileNo,
        "casteStateId" : this.state.caste,
        "transportTypeId" : this.state.transportStatus,
        "partyId" : this.state.party,
        "voterCardNo":this.state.updateVoterCardNo,
        "voterSlipGiven":this.state.voterSlipGiven,
        "demoBalletGiven":this.state.demoBalletGiven

      }
    ); 

    

    //addNewVoter
    getCampaignAPICallData(addNewVoterObj,"updateMlcVoterAttributes")
        .then((res) => res.json())
        .then(async(data) => {
          if(data !=null){
            if(data.status == "Success"){
              this.setState({newMemberAddedMsg:"Updated Voter SuccessFully..." });
              setTimeout(() => {
                this.setState({newMemberAddedMsg:"",updateMLCVoterAttributesModal:false,validateUpdateVoterCardNo:"",validateUpdateTransportStatus:"",validateUpdateCaste:"",validateUpdateParty:"",validateUpdateMobileNo:""})
                this.setState({updateVoterCardNo:"",transportStatus:"",caste:"",party:"",mobileNo:""})
                let new_updated_data = this.state.MLCVotersListData.map((item) => {
                  if (item.voterId == voter_id) {
                      return {
                          ...item,
                          isUpdated: "Y",
                      };
                  }
                  return item;
              });
              this.setState({MLCVotersListData:new_updated_data})
                /*if(this.state.MLCvoterType == "CUBS"){
                  this.getMlcVoterDetailsForCampaignSurvey(this.state.cubsLocationScopeId,this.state.cubsLocationId);
                }else{
                  this.getMlcVoterDetailsForCampaignSurveyForMandalView();
                }*/
            }, 2000);
            
            }else if(data.status == "Fail"){
              if(data.message == "Invalid Inputs"){
                this.setState({newMemberAddedMsg:"Please Enter Valid Inputs...." });
              }else{
                this.setState({newMemberAddedMsg:data.message });
              }
              
            }
          }else{
            this.setState({newMemberAddedMsg:"Something Wrong,Please Try Again..." });
          }
         
          
          
    });
    
  }


  render() {
      return (
        <section >
          <div className='bg_red_event'>
              <div className='flex_event_name_top'>
                  <div>
                  <Link to={{pathname: "/ongoing_ready_event",
                      params: { 
                        navbarDisplay:"hide",
                        event:this.props?.location?.params?.event == undefined ? "" : this.props?.location?.params?.event,
                        eventStatus:this.props?.location?.params?.eventStatus == undefined ? "" : this.props?.location?.params?.eventStatus,
                        campaignDetailsId:this.props?.location?.params?.campaignDetailsId == undefined ? "" : this.props?.location?.params?.campaignDetailsId,
                        eventType:this.props?.location?.params?.eventType == undefined ? "" : this.props?.location?.params?.eventType,
                        isAttended:this.props?.location?.params?.eventType == "other_programs" ? "Yes" : this.props?.location?.params?.isAttended == undefined ? "" : this.props?.location?.params?.isAttended,
                        surveyType:this.props?.location?.params?.surveyType == undefined ? "" : this.props?.location?.params?.surveyType
                      
                      }}}> <Image src={arrowLeft} alt="Arrow" ></Image></Link>
                      
                  </div>  
                  <div>
                      <h6 className='color_white fw-bold mt-3'>Today Party Event & Activities</h6>
                  </div> 
              </div>
              <div className='white_block_with_left_border mt-2'>
                  <h6 className='f-14 fw-bold'>{this.state.event.campaignName} </h6>
                  <div className='flex_PA mt-2'>
                      <div>
                          <h6 className='f-12'>Location</h6>
                          <h6 className='f-12'>{this.state.event.level} - {this.state.event.location}</h6>
                      </div>
                      
                  </div>
                  <div className='mt-1'>
                      <h6 className='f-12'>Date</h6>
                      <h6 className='f-12'>{this.state.event.fromDate} To {this.state.event.todate}</h6>
                  </div>
              
              </div>
          </div>
        
         <Container>
          <Row>
            <Col>
                <div className='flex-box-Status'>
                    <h6 className='f-14 fw-bold mt-4'>Status Type :</h6>
                    <div className='mt-4'>
                      <Row>
                        <Col>
                          <Form className="">
                              <Form.Check
                                inline
                                label="Visited"
                                name="mlc_voters_block"
                                type="radio"
                                id="visitedStatusId"
                                onChange={()=>this.selectStatusType("Visited",'Y')}
                                className="f-14 fw-bold"
                                value="Y"
                              />
                            
                              <Form.Check
                                inline
                                label="Pending"
                                name="mlc_voters_block"
                                type="radio"
                                id="pendingStatusId"
                                onChange={()=>this.selectStatusType("Pending","N")}
                                className="f-14 fw-bold"
                                value="N"
                              />
                              <Form.Check
                                inline
                                label="All"
                                name="mlc_voters_block"
                                type="radio"
                                id="allStatusId"
                                onChange={()=>this.selectStatusType("All",0)}
                                className="f-14 fw-bold"
                                value=""
                                defaultChecked={true}
                              />
                          </Form>
                        </Col>
                      </Row>
                      
                    </div>
                </div>
            </Col>
          </Row>
         <Row>
            <Col sm={12} className="">
                <div className='border_pad mt-2'>
                <Row>
                        <Col>
                          <Form >
                              <Form.Check
                                inline
                                label="CUBS"
                                name="voters_type"
                                type="radio"
                                id="crudViewId"
                                defaultChecked={true}
                                onChange={()=>this.selectedVoterType("CUBS")}
                                className="f-14 fw-bold"
                                value="CRUD"
                              />
                            
                              <Form.Check
                                inline
                                label="Mandal"
                                name="voters_type"
                                type="radio"
                                id="mandalViewId"
                                onChange={()=>this.selectedVoterType("mandal")}
                                className="f-14 fw-bold"
                                value="mandal"
                              />
                              <Form.Check
                                  inline
                                  label="MLC Booth"
                                  name="voters_type"
                                  type="radio"
                                  id="MLC_BoothId"
                                  onChange={()=>this.selectedVoterType("Mlc_booth")}
                                  className="f-14 fw-bold"
                                  value="Mlc_booth"
                              />
                          </Form>
                        </Col>
                      </Row>
                </div>
            </Col>
        </Row>
        {
          this.state.MLCvoterType == "CUBS" ? 
          (
            <>
            <Row>
              <Col className='mt-2'>
                    <label className="form-label f-13 fw-bold">Cluster 
                    </label>
                    <select id="cluster_id" name="clusterId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForCubs(e,"cluster")}>
                          {
                                this.state.MLC_clusterNames.length>0  ? 
                                (
                                    <>
                                    <option value="">Select Cluster</option>
                                    {
                                        this.state.MLC_clusterNames.map((item,index) => {
                                            return(
                                              <option key={index} value={item.id} >{item.name}</option>
                                              
                                            )
                                        })
                                    }
                                    </>
                                  
                                )
                                :
                                (<option value="">Select Cluster</option>)
                                
                            }
                    </select>
                    
                  </Col>
                  <Col className='mt-2'>
                    <label className="form-label f-13 fw-bold">Unit 
                    </label>
                    <select id="unit_id" name="unitId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForCubs(e,"unit")}>
                          {
                                this.state.MLC_unitNames.length>0  ? 
                                (
                                    <>
                                    <option value="">Select Unit</option>
                                    {
                                        this.state.MLC_unitNames.map((item,index) => {
                                            return(
                                              <option key={index} value={item.id} > {item.name}</option>
                                              
                                            )
                                        })
                                    }
                                    </>
                                  
                                )
                                :
                                (<option value="">Select Unit</option>)
                                
                            }
                    </select>
                    
                  </Col>
                  </Row>
                  <Row>
                <Col className='mt-2'>
                    <label className="form-label f-13 fw-bold">Booth 
                    </label>
                    <select id="booth_id" name="boothId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForCubs(e,"booth")}>
                          {
                                this.state.MLC_boothList.length>0  ? 
                                (
                                    <>
                                    <option value="">Select Booth</option>
                                    {
                                        this.state.MLC_boothList.map((item,index) => {
                                            return(
                                              <option key={index} value={item.id} >Booth No - {item.name}</option>
                                              
                                            )
                                        })
                                    }
                                    </>
                                  
                                )
                                :
                                (<option value="">Select Booth</option>)
                                
                            }
                    </select>
                    
                  </Col>
                 {/* <Col>
                      <Button type="button" className='btn btn-sm btn-success float-end m_top45_right_40' onClick={(e) => this.getMLCVoters(e,"cubs")}>GET Voters</Button>
                    </Col>*/}
              </Row>
              </>
          )
          :
          (
            this.state.MLCvoterType == "mandal" ? 
            (
              <>
                <Row>
                  <Col className='mt-2'>
                        <label className="form-label f-13 fw-bold">Mandal 
                        </label>
                        <select id="mandal_id" name="mandalId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForMandal(e,"mandal")}>
                              {
                                    this.state.MLC_MandalNames.length>0  ? 
                                    (
                                        <>
                                        <option value="">Select Mandal</option>
                                        {
                                            this.state.MLC_MandalNames.map((item,index) => {
                                                return(
                                                  <option key={index} value={item.id} attr_name={item.name} >{item.name}</option>
                                                  
                                                )
                                            })
                                        }
                                        </>
                                      
                                    )
                                    :
                                    (<option value="">Select Mandal</option>)
                                    
                                }
                        </select>
                        {
                          this.state.validateReason !="" ?
                          (<span className='f-12 color_red'>{this.state.validateReason}</span>)
                          :
                          ("")
                        }
                        
                      </Col>
                      <Col className='mt-2'>
                        <label className="form-label f-13 fw-bold">Village 
                        {
                        this.state.villageCheckLoader == 1 ?
                          (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image> )
                          :
                          ("")
                        }
                        
                        </label>
                        <select id="village_id" name="villageId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForMandal(e,"village")}>
                              {
                                    this.state.MLC_VillageNames.length>0  ? 
                                    (
                                        <>
                                        <option value="">Select Village</option>
                                        {
                                            this.state.MLC_VillageNames.map((item,index) => {
                                                return(
                                                  <option key={index} value={item.name} > {item.name}</option>
                                                  
                                                )
                                            })
                                        }
                                        </>
                                      
                                    )
                                    :
                                    (<option value="">Select Village</option>)
                                    
                                }
                        </select>
                        {
                          this.state.validateReasonForVillage !="" ?
                          (<span className='f-12 color_red'>{this.state.validateReasonForVillage}</span>)
                          :
                          ("")
                        }
                        
                      </Col>
                      </Row>
                      <Row>
                    <Col className='mt-2'>
                        <label className="form-label f-13 fw-bold">Street 
                        {
                          this.state.streetCheckLoader == 1 ?
                          (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>)
                          :
                          ("")
                        }
                        
                        </label>
                        <select id="street_id" name="streetId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForMandal(e,"street")}>
                              {
                                    this.state.MLC_StreetNames.length>0  ? 
                                    (
                                        <>
                                        <option value="">Select Street</option>
                                        {
                                            this.state.MLC_StreetNames.map((item,index) => {
                                                return(
                                                  <option key={index} value={item.id} > {item.name}</option>
                                                  
                                                )
                                            })
                                        }
                                        </>
                                      
                                    )
                                    :
                                    (<option value="">Select Street</option>)
                                    
                                }
                        </select>
                        {
                          this.state.streetCheckMessage == 1  ?
                          (<span className='f-12 color_red'>No Streets Available...</span>)
                          :
                          ("")
                        }
                        
                      </Col>
                      <Col>
                          <Button type="button" className='btn btn-sm btn-success float-end m_top45_right_40' onClick={(e) => this.getMLCVotersForMandalView(e,"mandal")}>GET Voters</Button>
                      </Col>
                    
                  </Row>
                  {
                          this.state.streetCheckMessage == 1  ?
                          (<Row>
                            <Col>
                                <h6 className='f-12 mt-3'>Note : If Streets are not Available,please select village name then submit Get Voters</h6>
                              </Col>
                          </Row>)
                          :
                          ("")
                        }
                  
                
                  </>
            )
            :
            (
              <>
                <Row>
                <Col className='mt-2'>
                        <label className="form-label f-13 fw-bold">MLC Booth Location 
                        </label>
                        <select id="MLC_Booth_LocId" name="MLCBoothLocId" className='form-select form-select-sm' onChange={(e) => this.handleOnchangeSelectBoxForMLCBooth(e,"MLCBoothLocId")}>
                            {
                                    this.state.MLC_BoothLoc.length>0  ? 
                                    (
                                        <>
                                        <option value="">Select Location</option>
                                        {
                                            this.state.MLC_BoothLoc.map((item,index) => {
                                                return(
                                                <option key={index} value={item.id} attr_name={item.name} >{item.name}</option>
                                                
                                                )
                                            })
                                        }
                                        </>
                                    
                                    )
                                    :
                                    (<option value="">Select Location</option>)
                                    
                                }
                        </select>
                        {
                        this.state.validateReasonMLCBoothLoc !="" ?
                        (<span className='f-12 color_red'>{this.state.validateReasonMLCBoothLoc}</span>)
                        :
                        ("")
                        }
                        
                    </Col>
                    </Row>
                    </>
            )
            
          )
        }

       

        <Row>
          <Col>
          {
              this.state.MLCVotersListCheckLoderSymbol == "show" && this.state.MLCVotersListData.length == 0  ?
              (
                <Row>
                  <Col sm={12}>
                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                  </Col>
                </Row>
              )
              :
              ("")
            }


            
            {
                    this.state.MLCVotersListData.length>0 ?
                      (
                        
                        <Row>
                            <Col sm={12}>
                            <h6 className='f-14 mt-3'>
                                Note : <span className='ml_5 mr_2'><i class="fa-solid fa-eye color_red f-14"></i> Visit Voter,</span>
                                        <span className='ml_5 mr_2'><i class="fa-solid fa-circle-info  f-14"></i> Voter Update</span>
                                        <span className='ml_5 mr_2'><i class="f-14 fa-solid fa-circle-check color_green"></i> Visited</span>
                                        <span className='ml_5 mr_2 mt-2'><i class="fa-solid fa-circle-info color_green  f-14"></i> Updated Voter</span>
                            </h6>
                            </Col>
                            <Col sm={12}>
                                <div className='bg_light_blue mt-3'>
                                    <h6 className='f-14'> {this.state.selectedVoterListTypeName} MLC Voter Details : 
                                    {
                                      this.state.visitedMessageDisplay != "" ?
                                      (<span className='f-12 color_green fw-bold mt-2 mb-2'>{this.state.visitedMessageDisplay}</span>)
                                      :
                                      ("")
                                    }
                                    </h6>
                                    
                                    <CardLessDataTableWithSearch 
                                        parentData={this.MLCvotersData()}
                                        particlecolumns={this.MLCvotersDataColumns()}
                                        defaultSorted={[{
                                            dataField: "VoterName",
                                            order: "desc"
                                        }]}
                                        tableHeading=""
                                        />
                                    
                                </div>     
                            </Col> 

                                 

                      </Row>
                      )
                      :
                      (
                        <>
                          {
                          this.state.votersListCheck == 1 ?
                          (<h6 className='f-14 text-center mt-4'>No Voters Availble in this House</h6>)
                          :
                          ("")
                        }
                        </>
                      )
                        
                    }  
          
          </Col>
        </Row>

          <UpdateMLCAttributesSavingModal
                show={this.state.updateMLCVoterAttributesModal}
                onHide={() => this.setState({ updateMLCVoterAttributesModal: false })}
                stateData = {this.state}
                phandleOnchangeSetUpdateVoterDts={this.handleOnchangeSetUpdateVoterDts}
                pUpdateMlcVoterAttributes={this.UpdateMlcVoterAttributes}
            />
        
        </Container>
        </section>
      )
  }
}

export default (MLCVotersSection);
