(function () {
    window.User ={}
    var unsafeData = window.Telegram.WebApp.initDataUnsafe;
    var telegramUser = unsafeData.user;
    window.User.first_name = telegramUser !=undefined ? telegramUser.first_name : "";
    window.User.last_name = telegramUser !=undefined ?telegramUser.last_name : "";
    window.User.chatID = telegramUser !=undefined ?telegramUser.id : "";
    window.User.bot_name = telegramUser !=undefined ?telegramUser.bot_name : "";

    let startParam = window.Telegram.WebApp.initDataUnsafe.start_param

	 console.log("telegramUser",unsafeData)
     console.log("telegramUser",startParam)
})()