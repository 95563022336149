

export async function saveMemberDetails(data) {
    
    const response  =    fetch('/bot/add-member', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
     body: data
  })

   return response;
}


export async function getAPICallData(data,url) {
    
    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
      }
    const rndInt = randomIntFromInterval(0,1);
    
    var serverNamesArr=[{"id":0,"name" :"BSA"},{"id":1,"name" :"BSA"}];
    let serverName="";

    /*if(url == "FTRGRT/getWithoutVoterEnrollmentsCountByUserByDate" || url == "FTRGRT/getEnrollmentsCountByUserByDate" ||
        url == "FTRGRT/getLocationWiseFGEnrollmentsInAConstituency" || url == "FTRGRT/getFGEnrollmentCountsInUserLocation" || 
        url == "FTRGRT/getHouseDetailsInABooth" || url == "FTRGRT/getVotersInAHouse"){
        
            serverName = "BSA4";
    }else {
        for(var i in serverNamesArr){
            if(rndInt == serverNamesArr[i].id){
                serverName = serverNamesArr[i].name
            }
        }
        
    }*/

   for(var i in serverNamesArr){
        if(rndInt == serverNamesArr[i].id){
            serverName = serverNamesArr[i].name
        }
   }

    const response  =    fetch('https://www.mypartydashboard.com/'+serverName+'/WebService/'+url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'user_data':window.Telegram.WebApp.initData
        },
     body: data
  })

   return response;
}

export async function getCampaignAPICallData(data,url) { 
    
    const response  =    fetch('https://www.mypartydashboard.com/BSA/WebService/Campaign/'+url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'user_data':window.Telegram.WebApp.initData
        },
     body: data
  })

   return response;
}


export async function getAPICallDataForOnlyBSA(data,url) {

    const response  =  fetch('https://www.mypartydashboard.com/BSA/WebService/'+url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'user_data':window.Telegram.WebApp.initData
            },
        body: data
    })
    return response;
        
        
    }

    export async function getAPICallDataForOnlyBSA4(data,url) {

        const response  =  fetch('https://www.mypartydashboard.com/BSA4/WebService/'+url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'user_data':window.Telegram.WebApp.initData
                },
            body: data
        })
        return response;
            
            
        }

        export async function getAPICallDataForCadre(data,url) {

            const response  =  fetch('https://www.mypartydashboard.com/CDR/WebService/'+url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                body: data
            })
            return response;
                
                
        }
    
        export async function getAPICallDataForCadreSaving(data,url) {
    
            const response  =  fetch('https://www.mypartydashboard.com/CR/WebService/'+url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                body: data
            })
            return response;
                
                
        }


        export async function getAPICallDataForPayment(data,url) {

            const response  =  fetch('https://www.mypartydashboard.com/CR/WebService/PG/'+url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                body: data
            })
            return response;
                
                
        }